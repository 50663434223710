import React, { useEffect, useState } from "react";
import {
    Card,
    CardContent,
    Box,
    TextField,
    Button,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    FormControl,
    Select,
} from "@mui/material";
import { Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CourseApiService from "../../services/api/courseAPIService";

const userInfo = localStorage.getItem("user");
const parsedUserInfo = JSON.parse(userInfo || "{}");
const { token } = parsedUserInfo;

// Predefined subjects by grade
const subjectsByGrade = {
    4: [
        'Natural Science and Technology',
        'Life Skills',
        'Economics Management Science',
        'English First Language',
        'English First Additional Language',
        'Mathematics',
        'Robotics',
        'Accounting'
    ],
    5: [
        'Natural Science and Technology',
        'Life Skills',
        'Economics Management Science',
        'English First Language',
        'English First Additional Language',
        'Mathematics',
        'Robotics',
        'Accounting'
    ],
    6: [
        'Natural Science and Technology',
        'Life Skills',
        'Economics Management Science',
        'English First Language',
        'English First Additional Language',
        'Mathematics',
        'Robotics',
        'Accounting'
    ],
    7: [
        'Natural Science',
        'Technology',
        'Social Sciences',
        'Economics Management Science',
        'Mathematics',
        'English First Language',
        'English First Additional Language',
        'Life Orientation'
    ],
    8: [
        'Natural Science',
        'Technology',
        'Social Sciences',
        'Economics Management Science',
        'Mathematics',
        'English First Language',
        'English First Additional Language',
        'Life Orientation'
    ],
    9: [
        'Natural Science',
        'Technology',
        'Social Sciences',
        'Economics Management Science',
        'Mathematics',
        'English First Language',
        'English First Additional Language',
        'Life Orientation'
    ],
    10: [
        'Business Studies',
        'Accounting',
        'Chemistry',
        'Mathematics',
        'Mathematics Literacy',
        'Physical Science',
        'Economics',
        'Life Science',
        'History',
        'Geography'
    ],
    11: [
        'Business Studies',
        'Accounting',
        'Chemistry',
        'Mathematics',
        'Mathematics Literacy',
        'Physical Science',
        'Economics',
        'Life Science',
        'History',
        'Geography'
    ],
    12: [
        'Business Studies',
        'Accounting',
        'Chemistry',
        'Mathematics',
        'Mathematics Literacy',
        'Physical Science',
        'Economics',
        'Life Science',
        'History',
        'Geography'
    ]
};

const ManageCourses = () => {
    const [courses, setCourses] = useState([]);
    const [newCourse, setNewCourse] = useState({ courseName: '', grade: '', subject: '' });
    const [filteredSubjects, setFilteredSubjects] = useState([]);
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);  // Track if editing
    const [editCourseId, setEditCourseId] = useState(null);  // Track course to edit
    const navigate = useNavigate();

    // Fetch courses on component mount
    useEffect(() => {
        fetchCourses();
    }, []);

    // Fetch all courses for the teacher
    const fetchCourses = async () => {
        try {
            const response = await CourseApiService.getCoursesForTeacher(token);
            if (response.ok) {
                const coursesData = await response.json();
                setCourses(coursesData);
            } else {
                console.error("Failed to fetch courses.");
            }
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    // Add or edit a course
    const handleCourseSubmit = async (e) => {
        e.preventDefault();
        const courseData = {
            course_name: newCourse.courseName,
            grade: newCourse.grade,
            subject_name: newCourse.subject,
            start_date: new Date().toISOString().split('T')[0],
            end_date: new Date().toISOString().split('T')[0]
        };

        try {
            let response;
            if (isEditing && editCourseId) {
                // Editing an existing course
                response = await CourseApiService.updateCourse(editCourseId, courseData, token);
            } else {
                // Creating a new course
                response = await CourseApiService.createCourse(courseData, token);
            }

            if (response.ok) {
                fetchCourses(); // Refresh the list of courses
                handleCloseModal();
            } else {
                console.error("Failed to save course.");
            }
        } catch (error) {
            console.error("Error saving course:", error);
        }
    };

    // Open the modal to edit a course
    const handleOpenEditModal = (course) => {
        setIsEditing(true);
        setEditCourseId(course.id);
        setNewCourse({
            courseName: course.name,
            grade: course.grade,
            subject: course.subject
        });
        setFilteredSubjects(subjectsByGrade[course.grade] || []);
        setOpen(true);
    };

    // Delete course
  const handleCourseDelete = async (courseId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this course?");

    if (isConfirmed) {
        try {
            await CourseApiService.deleteCourse(courseId, token);
            console.log("Course deleted successfully.");
            fetchCourses(); // Refresh the course list after deletion
        } catch (error) {
            console.error("Error deleting course:", error);
        }
    }
    };

    // Open the modal to add a course
    const handleOpenModal = () => {
        setIsEditing(false);  // Reset editing state
        setEditCourseId(null);  // Clear any existing course ID
        setNewCourse({ courseName: '', grade: '', subject: '' });
        setOpen(true);
    };

    // Close the modal
    const handleCloseModal = () => {
        setOpen(false);
    };

    // Handle grade change and filter the subjects based on the selected grade
    const handleGradeChange = (event) => {
        const selectedGrade = event.target.value;
        setNewCourse({ ...newCourse, grade: selectedGrade, subject: '' });
        setFilteredSubjects(subjectsByGrade[selectedGrade] || []);
    };

    return (
        <div>
            <Card
                sx={{
                    borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: { xs: '5px', sm: '30px', md: '40px' },
                    width: { xs: '100vw', sm: '100vw', md: '80vw' },
                    maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                    margin: { xs: '0px', sm: '30px', md: '40px' },
                }}
            >
                <CardContent>
                   <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
    <Typography variant="h5">Manage Courses</Typography>

    <Button variant="contained" color="primary" onClick={handleOpenModal}>
        {isEditing ? "Edit Course" : "Add Course"}
    </Button>
</Box>


                    <Table
                    sx={{
        maxHeight: '300px', // Set a max-height or height to enable scrolling
        overflowY: 'auto',
    }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Course Name</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Subject</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {courses.map((course, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Button onClick={() => navigate(`/manage-learners/${course.id}/${course.name}/${course.grade}`)}>
                                            {course.name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{course.grade ? `Grade ${course.grade}` : 'No Grade'}</TableCell>
                                    <TableCell>{course.subject}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleOpenEditModal(course)} color="primary">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleCourseDelete(course.id)} color="tertiary">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {/* Add or Edit Course Modal */}
                    <Dialog open={open} onClose={handleCloseModal}>
                        <DialogTitle>{isEditing ? "Edit Course" : "Add Course"}</DialogTitle>
                        <DialogContent>
                            <TextField
                                fullWidth
                                label="Course Name"
                                name="courseName"
                                value={newCourse.courseName}
                                onChange={(e) => setNewCourse({ ...newCourse, courseName: e.target.value })}
                                sx={{
                                    width: "100%",
                                    height: "35px",
                                    marginTop: "16px",
                                    "& .MuiInputBase-root": { height: "35px" },
                                    "& fieldset": { borderColor: "#5F9EA0", borderRadius: "10px" }
                                }}
                            />

                            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                                <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                                    Grade<span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                                    <Select
                                        name="grade"
                                        value={newCourse.grade}
                                        onChange={handleGradeChange}
                                        displayEmpty
                                        sx={{ height: '35px' }}
                                    >
                                        <MenuItem value="" disabled>Select a grade</MenuItem>
                                        {Object.keys(subjectsByGrade).map((grade, index) => (
                                            <MenuItem key={index} value={grade}>{`Grade ${grade}`}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                                <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                                    Subject<span style={{ color: 'red' }}>*</span>
                                </Typography>
                                <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                                    <Select
                                        name="subject"
                                        value={newCourse.subject}
                                        onChange={(e) => setNewCourse({ ...newCourse, subject: e.target.value })}
                                        displayEmpty
                                        sx={{ height: '35px' }}
                                    >
                                        <MenuItem value="" disabled>
                                            Select a subject
                                        </MenuItem>
                                        {filteredSubjects.map((subject, index) => (
                                            <MenuItem key={index} value={subject}>{subject}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseModal} color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={handleCourseSubmit} color="primary" disabled={!newCourse.grade || !newCourse.subject || !newCourse.courseName}>
                                {isEditing ? "Save Changes" : "Add Course"}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </CardContent>
            </Card>
        </div>
    );
};

export default ManageCourses;