import { TextField, Button, Typography, IconButton, InputAdornment, Box, Link, Checkbox, FormControlLabel, LinearProgress } from '@mui/material';
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import './Registration.css';
import { AuthenticationApiService } from "../../../services/api/authenticationAPIService";
import Footer from "../footer/Footer"; // Assuming the Footer component is already created

const Registration = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirmPassword: "",
        acceptTerms: false,
    });

    const [formErrors, setFormErrors] = useState({
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        confirmPassword: false,
    });

    const [passwordError, setPasswordError] = useState({
        length: false,
        specialCharacter: false,
        number: false,
        upperCase: false,
    });

    const [passwordTouched, setPasswordTouched] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [showPassword, setShowPassword] = useState(false);

    const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });

    // Trigger validation based on the field name
    switch (name) {
        case "first_name":
        case "last_name":
        case "email":
            setFormErrors(prevErrors => ({ ...prevErrors, [name]: !value }));
            break;
        case "password":
            validatePassword(value);
            calculatePasswordStrength(value);
            break;
        case "confirmPassword":
            setFormErrors(prevErrors => ({
                ...prevErrors,
                confirmPassword: value !== formData.password,
            }));
            break;
        default:
            break;
    }
 };


    const handleBlur = (event) => {
        const { name } = event.target;
        if (name === "password") {
            setPasswordTouched(true);
        }
    };

    const handleCheckboxChange = (event) => {
        setFormData({ ...formData, acceptTerms: event.target.checked });
    };

    const validatePassword = (value) => {
        const specialCharacterRegex = /[!@#$%^&*()='"`_+{}[\]:;<>,.?~\\/-]/;
        const upperCaseRegex = /[A-Z]/;
        const errors = {
            length: value.length < 8 || value.length > 20,
            specialCharacter: !specialCharacterRegex.test(value),
            number: !/[0-9]/.test(value),
            upperCase: !upperCaseRegex.test(value),
        };
        setFormErrors(prevErrors => ({ ...prevErrors, password: Object.values(errors).includes(true) }));
    };

    const calculatePasswordStrength = (password) => {
        let strength = 0;
        if (password.length >= 8) strength += 20;
        if (/[A-Z]/.test(password)) strength += 20;
        if (/[a-z]/.test(password)) strength += 20;
        if (/\d/.test(password)) strength += 20;
        if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(password)) strength += 20;
        setPasswordStrength(strength);
    };

    const handleSignUp = async (event) => {
        event.preventDefault();

        const errors = {
            first_name: !formData.first_name,
            last_name: !formData.last_name,
            email: !formData.email,
            password: !formData.password || Object.values(passwordError).includes(true),
            confirmPassword: formData.password !== formData.confirmPassword,
        };

        setFormErrors(errors);

        const isValid = Object.values(errors).every((error) => !error) && formData.acceptTerms;
        try {
            const response = await AuthenticationApiService.register(formData);

            if (response.ok) {
                await Swal.fire("Success", "You have registered successfully!", "success");

                const res = await response.json();
                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        token: res.token,
                        name: `${res.first_name} ${res.last_name}`,
                        userId: res.user_id,
                        email: res.email,
                    })
                );
                navigate('/onboarding-page');
            } else {
                await Swal.fire("Error", "Registration failed. Please try again.", "error");
            }
        } catch (error) {
            await Swal.fire("Error", "Something went wrong. Please try again.", "error");
        }
    };

    return (
        <Grid container className="sign-up-page-container">
            <Grid item xs={12} className="sign-up-page-card-wrapper">
                <Box className="sign-up-page-card">
                    <p className="signup-title">
                        Sign up for Educase
                    </p>
                    <form onSubmit={handleSignUp} style={{ padding: '20px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="First name"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="First name"
                                    name="first_name"
                                    value={formData.first_name}
                                    onChange={handleInputChange}
                                    error={formErrors.first_name}
                                    helperText={formErrors.first_name ? "First name is required" : ""}
                                    margin="dense"
                                    sx={{
                                        "& .MuiInputBase-root": { height: "45px" },
                                        "& .MuiInputLabel-root": {
                                            fontWeight: "bold",
                                            fontSize: "14px",
                                            color: "#323e48",
                                        },
                                        "& fieldset": {
                                            borderColor: "#5F9EA0",
                                            borderRadius: "10px",
                                        },
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Last name"
                                    variant="outlined"
                                    fullWidth
                                    placeholder="Last name"
                                    name="last_name"
                                    value={formData.last_name}
                                    onChange={handleInputChange}
                                    error={formErrors.last_name}
                                    helperText={formErrors.last_name ? "Last name is required" : ""}
                                    margin="dense"
                                    sx={{
                                        '& .MuiInputBase-root': { height: '45px' },
                                        '& .MuiInputLabel-root': { fontWeight: 'bold', fontSize: '14px', color: '#323e48' },
                                        '& fieldset': {
                                            borderColor: '#5F9EA0',
                                            borderRadius: '10px'
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            placeholder="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                            error={formErrors.email}
                            helperText={formErrors.email ? "Email is required" : ""}
                            margin="dense"
                            sx={{
                                '& .MuiInputBase-root': { height: '45px' },
                                '& .MuiInputLabel-root': { fontWeight: 'bold', fontSize: '14px', color: '#323e48' },
                                '& fieldset': {
                                    borderColor: '#5F9EA0',
                                    borderRadius: '10px'
                                }
                            }}
                        />
                        <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            placeholder="Password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            onChange={handleInputChange}
                            onBlur={handleBlur}
                            error={passwordTouched && formErrors.password}
                            helperText={
                                passwordTouched && formErrors.password ? (
                                    "Password must contain at least one number, one special character, and one uppercase letter"
                                ) : ""
                            }
                            margin="dense"
                            sx={{
                                '& .MuiInputBase-root': { height: '45px' },
                                '& .MuiInputLabel-root': { fontWeight: 'bold', fontSize: '14px', color: '#323e48' },
                                '& fieldset': {
                                    borderColor: passwordTouched && formErrors.password ? 'red' : '#5F9EA0',
                                    borderRadius: '10px'
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {/* Password Strength Indicator */}
                        <Box sx={{ width: '100%', marginTop: '8px' }}>
                            <LinearProgress
                                variant="determinate"
                                value={passwordStrength}
                                sx={{
                                    height: '6px',
                                    borderRadius: '5px',
                                    backgroundColor: '#e0e0df',
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: passwordStrength > 80 ? '#4caf50' : passwordStrength > 50 ? '#ffeb3b' : '#f44336',
                                    },
                                }}
                            />
                        </Box>

                        <TextField
                            label="Confirm Password"
                            variant="outlined"
                            fullWidth
                            placeholder="Password confirmation"
                            name="confirmPassword"
                            type={showPassword ? "text" : "password"}
                            value={formData.confirmPassword}
                            onChange={handleInputChange}
                            error={formErrors.confirmPassword}
                            helperText={formErrors.confirmPassword ? "Passwords do not match" : ""}
                            margin="dense"
                            sx={{
                                '& .MuiInputBase-root': { height: '45px' },
                                '& .MuiInputLabel-root': { fontWeight: 'bold', fontSize: '14px', color: '#323e48' },
                                '& fieldset': {
                                    borderColor: '#5F9EA0',
                                    borderRadius: '10px'
                                }
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.acceptTerms}
                                    onChange={handleCheckboxChange}
                                    name="acceptTerms"
                                />
                            }
                            label={
                                <Typography
                                    variant="body2"
                                    sx={{ textAlign: "left", display: "block", color: '#323e48' }}
                                >
                                    I accept the <Link href="#">Terms of Service</Link>.
                                </Typography>
                            }
                            sx={{
                                marginTop: '10px',
                                width: '100%',
                                alignItems: 'center',
                            }}
                        />

                        <Button
                            variant="contained"
                            fullWidth
                            type="submit"
                            className="sign-up-page-submit-button"
                            sx={{
                                backgroundColor: formData.acceptTerms ? "#00c1e4" : "#B0BEC5",  // Disabled color if not accepted
                                color: "#fff",
                                padding: "12px 0",
                                fontSize: "16px",
                                borderRadius: "10px",
                                marginTop: "20px",
                                "&:hover": {
                                    backgroundColor: formData.acceptTerms ? "#00b0d4" : "#B0BEC5",
                                },
                            }}
                            disabled={!formData.acceptTerms}  // Disable button if terms are not accepted
                        >
                            Submit
                        </Button>

                        <Button
                            fullWidth
                            className="login-page-link-button"
                            sx={{
                                backgroundColor: "#e6f7fc",
                                color: "#00c1e4",
                                padding: "12px 0",
                                fontSize: "16px",
                                borderRadius: "10px",
                                marginTop: "10px",
                                "&:hover": {
                                    backgroundColor: "#d4f1fa",
                                },
                            }}
                            onClick={() => navigate('/auth/login')}
                        >
                            Already have an account?
                        </Button>
                    </form>
                    <Footer />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Registration;
