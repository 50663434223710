import React from 'react';
import './TermsOfService.css';
import Header from "../../components/landingPage/header/header";
import Footer from "../../components/landingPage/footer/footer";

const TermsOfService = () => {
    return (
        <div className="terms-of-service">
            <Header className="section-spacing"/>
            <div class="terms-content">
                <div className="terms-header">
                    <h1 className="terms-title">Terms of Service</h1>
                    <p className="terms-updated">Updated September 30, 2024</p>
                </div>

                <p className="terms-p">
                    From everyone at Educase Tools, thank you for using our products! We build them to help you empower
                    teachers and students. Thousands of educators and students use Educase Tools every day. Since we
                    don't
                    know all our users personally, we have to put some Terms of Service in place to maintain smooth
                    operations.<br />


                    <br />
                    When we say "Company," "we," "our," or "us" in this document, we are referring to Educase Tools,
                    operated by X9 Studios Inc.
                    <br />
                    <br />
                    When we say "Services," we refer to our websites, including educasetools.com, and any product
                    created and maintained by Educase Tools. This includes all products, whether delivered via web
                    browsers,
                    desktop applications, mobile applications, or any other format.
                    <br />
                    <br />
                    When we say "You" or "your," we refer to the people or organizations that own an account with one or
                    more of our Services. We have specific ownership policies for all versions of Educase Tools.
                    <br />
                    <br />
                    We may update these Terms of Service ("Terms") in the future. Changes are usually to clarify certain
                    terms by linking to expanded policies. Whenever we make significant changes, we will refresh the
                    date at the top of this page.
                    <br />
                    <br />
                    By using our Services, you agree to the most recent version of these Terms. Even if we don’t
                    exercise or enforce a right or provision, that doesn’t mean we are waiving it. These Terms include a
                    limitation of our liability.
                    <br />
                    <br />
                    If you violate any of the Terms, we may terminate your account. Trust is important to us, and we
                    work to maintain it by being open about who we are, how we operate, and always welcoming feedback.
                </p>

                <h2 className="terms-h2">Account Terms</h2>

                <ul className="terms-ul">
                    <li className="terms-li">You are responsible for maintaining the security of your account and
                        password, and ensuring the same
                        for any users under your account. We recommend setting up two-factor authentication for added
                        security,
                        and it may be required in some of our Services.
                    </li>
                    <li className="terms-li">You may not use the Services for any restricted purposes outlined in our
                        Use Restrictions Policy,
                        nor may you allow any of your users to do so.
                    </li>
                    <li className="terms-li">You are responsible for all content posted and activities occurring under
                        your account, including
                        those by your users.
                    </li>
                    <li className="terms-li">You must be a human. Accounts registered by automated methods or "bots" are
                        not permitted.
                    </li>
                </ul>

                <h2 className="terms-h2">Payment, Refunds, and Plan Changes</h2>

                <ul className="terms-ul">
                    <li className="terms-li">If you are using a free version of our Services, it is truly free: we do
                        not ask for your credit card,
                        and we do not sell your data.
                    </li>
                    <li className="terms-li">For paid Services with a free trial, we will specify the trial length
                        during sign-up. After the trial,
                        payment is required to continue. If no payment is made, your account will be frozen until
                        payment is
                        received. Prolonged account inactivity may lead to auto-cancellation.
                    </li>
                    <li className="terms-li">Upgrading from a free plan to a paid plan will charge your card
                        immediately, and your billing cycle
                        will begin on the day of the upgrade. For other upgrades or downgrades, the new rate applies
                        from the
                        next billing cycle.
                    </li>
                    <li className="terms-li">All fees exclude taxes, levies, or duties. Where applicable, we will
                        collect taxes on behalf of taxing
                        authorities. You are otherwise responsible for paying any taxes, levies, or duties.
                    </li>
                    <li className="terms-li">Refunds are processed according to our Fair Refund Policy.</li>
                </ul>

                <h2 className="terms-h2">Cancellation and Termination</h2>

                <ul className="terms-ul">
                    <li className="terms-li">You are solely responsible for canceling your account. To cancel, send us
                        an email or contact our
                        Support team for assistance.
                    </li>
                    <li className="terms-li">Upon account cancellation, your content will be immediately inaccessible.
                        After 30 days, all content
                        will be permanently deleted from active systems and logs, and after 60 days, from our backups.
                    </li>
                    <li className="terms-li">Upon service cancellation, you may use the service until the end of the
                        current paid month, after
                        which your account will switch to the Free version, and no further charges will apply.
                    </li>
                    <li className="terms-li">We reserve the right to suspend or terminate accounts and refuse future use
                        of our Services for any
                        reason. Suspension restricts access to the account and its content, while termination results in
                        permanent deletion of your account and content.
                    </li>
                    <li className="terms-li">Verbal, physical, or written abuse (including threats) against Educase
                        Tools staff will result in
                        immediate account termination.
                    </li>
                </ul>

                <h2 className="terms-h2">Modifications to Service and Pricing</h2>

                <p className="terms-p">
                    Sometimes we modify pricing structures. Existing customers are generally exempt from these changes,
                    but
                    we reserve the right to change prices for existing customers with at least 30 days' notice. We will
                    notify you via email and may post notices on our websites or Services.
                </p>

                <h2 className="terms-h2">Uptime, Security, and Privacy</h2>

                <ul className="terms-ul">
                    <li className="terms-li">Your use of the Services is at your own risk. Services are provided "as is"
                        and "as available."
                    </li>
                    <li className="terms-li">We reserve the right to temporarily disable your account if your usage
                        exceeds average usage
                        significantly. We'll reach out before taking action unless performance is impacted for other
                        users.
                    </li>
                    <li className="terms-li">We use encryption for data transmission and take measures to protect your
                        data through backups and
                        redundancies.
                    </li>
                    <li className="terms-li">On rare occasions, we may need to access personal data to resolve issues,
                        but we aim to minimize
                        exposure and fix the root cause to prevent future issues.
                    </li>
                    <li className="terms-li">For security purposes, we may examine logs and metadata, and access
                        accounts as part of abuse
                        investigations.
                    </li>
                    <li className="terms-li">We only share data when legally required, and under Canadian law. We also
                        work with subprocessors
                        listed in our Privacy Policy.
                    </li>
                    <li className="terms-li">Please refer to our Privacy Policy for more information about how we handle
                        your data.
                    </li>
                </ul>

                <h2 className="terms-h2">Copyright and Content Ownership</h2>

                <ul className="terms-ul">
                    <li className="terms-li">All content posted must comply with Canadian copyright law.</li>
                    <li className="terms-li">You retain ownership of your content, and we only use it as needed to
                        provide the Services.
                    </li>
                    <li className="terms-li">We reserve the right to remove content that violates the Terms.</li>
                    <li className="terms-li">Educase Tools retains all rights to the Services, and you may not duplicate
                        or exploit portions of the
                        Services without written permission. Requests for logo use must be sent to
                        support@educasetools.com.
                    </li>
                </ul>

                <h2 className="terms-h2">Features and Bugs</h2>

                <p className="terms-p">
                    We design our Services with care, based on our own experience and feedback from our users. However,
                    we
                    cannot guarantee that our Services will meet your specific requirements or be free of bugs. We
                    strive to
                    prioritize fixes, particularly those related to security or privacy.
                </p>

                <h2 className="terms-h2">Services Adaptations and API Terms</h2>

                <ul className="terms-ul">
                    <li className="terms-li">We offer Application Program Interfaces ("API"s) for some of our Services.
                        Any use of the API is subject
                        to these Terms and specific API-related terms.
                    </li>
                    <li className="terms-li">Abuse or excessive usage of the API may result in suspension of your
                        account’s access to the API. We
                        will try to warn account owners first, but if your usage causes downtime, we may cut access
                        without
                        notice.
                    </li>
                </ul>

                <h2 className="terms-h2">Liability</h2>

                <p className="terms-p">
                    You agree that Educase Tools is not liable for any direct, indirect, or incidental damages arising
                    from
                    your use of the Services. You are choosing to trust us with your data, and while we aim to protect
                    it,
                    the risk is ultimately yours.
                </p>

                <h2 className="terms-h2">Use Restrictions Policy</h2>
                <p className="terms-updated">Updated January 30, 2024</p>

                <p className="terms-p">
                    Many users and teams use Educase Tools products. We are proud to give them a better way to work. We
                    also recognize that however good the maker’s intentions, technology can amplify the ability to cause
                    great harm. That’s why we’ve established this policy. We feel an ethical obligation to counter such
                    harm: both in terms of dealing with instances where Educase Tools is used (and abused) to further
                    such harm, and to state unequivocally that the products we make at Educase Tools are not safe havens
                    for people who wish to commit such harm. If you have an account with any of our products, you can’t
                    use them for any of the restricted purposes listed below. If we find out you are, we will take
                    action.
                </p>

                <h3 className="terms-h3">Restricted purposes</h3>

                <ul className="terms-ul">
                    <li className="terms-li">Violence, or threats thereof: If an activity qualifies as
                        violent crime in Canada or where you live, you may not use Educase Tools products to plan,
                        perpetrate, or threaten that activity.
                    </li>
                    <li className="terms-li">Child exploitation, sexualization, or abuse: We don’t
                        tolerate any activities that create, disseminate, or otherwise cause child abuse. Keep away and
                        stop. Just stop.
                    </li>
                    <li className="terms-li">Hate speech: You cannot use our products to advocate for
                        the extermination, domination, or oppression of people.
                    </li>
                    <li className="terms-li">Harassment: Intimidating or targeting people or groups
                        through repeated communication, including using racial slurs or dehumanizing language, is not
                        welcome at Educase Tools.
                    </li>
                    <li className="terms-li">Doxing: If you are using Educase Tools products to share
                        other peoples’ private personal information for the purposes of harassment, we don’t want
                        anything to do with you.
                    </li>
                    <li className="terms-li">Malware or spyware: Code for good, not evil. If you are
                        using our products to make or distribute anything that qualifies as malware or spyware —
                        including remote user surveillance — begone.
                    </li>
                    <li className="terms-li">Phishing or otherwise attempting fraud: It is not okay to
                        lie about who you are or who you affiliate with to steal from, extort, or otherwise harm others.
                    </li>
                    <li className="terms-li">Spamming: No one wants unsolicited commercial emails. We
                        don’t tolerate folks (including their bots) using Educase Tools products for spamming purposes.
                        If your emails don’t pass muster with CAN-SPAM or any other anti-spam law, it’s not allowed.
                    </li>
                    <li className="terms-li">Cybersquatting: We don’t like username extortionists. If
                        you purchase an Educase Tools product account in someone else’s name and then try to sell that
                        account to them, you are cybersquatting. Cybersquatting accounts are subject to immediate
                        cancellation.
                    </li>
                    <li className="terms-li">Infringing on intellectual property: You can’t use Educase
                        Tools products to make or disseminate work that uses the intellectual property of others beyond
                        the bounds of fair use.
                    </li>
                </ul>

                <p className="terms-p">
                    While our use restrictions are comprehensive, they can’t be exhaustive — it’s possible an offense
                    could defy categorization, present for the first time, or illuminate a moral quandary we hadn’t yet
                    considered. That said, we hope the overarching spirit is clear: Educase Tools is not to be harnessed
                    for harm, whether mental, physical, personal, or civic. Different points of view — philosophical,
                    religious, and political — are welcome, but ideologies like white nationalism, or hate-fueled
                    movements anchored by oppression, violence, abuse, extermination, or domination of one group over
                    another, will not be accepted here.
                </p>

                <h3 className="terms-h3">Third-Party Services</h3>

                <p className="terms-p">
                    Educase Tools uses the YouTube API Services. By using our product, you are also agreeing to be bound
                    by the YouTube Terms of Service.
                </p>

                <h3 className="terms-h3">How to report abuse</h3>

                <p className="terms-p">
                    For cases of suspected malware, spyware, phishing, spamming, and cybersquatting, please alert us
                    at <a href="mailto:abuse@educasetools.com" className="terms-a">abuse@educasetools.com</a>.
                </p>

                <p className="terms-p">
                    For all other cases, please let us know by emailing <a href="mailto:report@educasetools.com"
                                                                           className="terms-a">report@educasetools.com</a>.
                    If you’re not 100% sure if something rises to the level of our use restrictions policy, report it
                    anyway.
                </p>

                <p className="terms-p">
                    Please share as much as you are comfortable with about the account, the content or behavior you are
                    reporting, and how you found it. Sending us a URL or screenshots is super helpful. If you need a
                    secure file transfer, let us know and we will send you a link. We will not disclose your identity to
                    anyone associated with the reported account.
                </p>

                <p className="terms-p">
                    Someone on our team will respond within one business day to let you know we’ve begun investigating.
                    We will also let you know the outcome of our investigation (unless you ask us not to, or we are not
                    allowed to under law).
                </p>
            </div>
            <Footer />
        </div>
    );
};

export default TermsOfService;
