import React from 'react';
import './CreateAssessmentTool.css';
import image1 from '../../../../assets/create-assessments-tool-pic5.png'; // Adjust paths as necessary
import Header from "../../header/header";
import Footer from "../../footer/footer";

const CreateAssessmentTool = () => {
  return (
    <div className="product-intro-container">
      <Header />
      <div className="product-intro-wrapper">
        <div className="product-intro-text">
          <h1>Seriously Speed up exam and test creation</h1>
          <p>Create tests and exams in less than 10 minutes with our question bank and drag-and-drop interface.</p>
          <button className="cta-button">Try for free</button>
        </div>
        <div className="product-intro-images">
          <img src={image1} alt="Create Assessment Example" className="rounded-corner-image" />
        </div>
      </div>

      {/* Steps Section */}
      <h2 className="steps-heading">Create assessments in 4 easy steps</h2>
      <div className="steps-container">
        {/* Step 1 */}
        <div className="step-row">
          <div className="step-card">
            <img src={image1} alt="Step 1" className="step-image rounded-corner-image" />
          </div>
          <div className="step-description">
            <h3>Step 1: Define your exam structure</h3>
            <p>Set up the outline and key details of your exam, making it easy to follow.</p>
          </div>
        </div>

        {/* Step 2 */}
        <div className="step-row step-row-reverse">
          <div className="step-card">
            <img src={image1} alt="Step 2" className="step-image rounded-corner-image" />
          </div>
          <div className="step-description">
            <h3>Step 2: Choose questions from the bank</h3>
            <p>Use our vast question bank to pick suitable questions for your exam.</p>
          </div>
        </div>

        {/* Step 3 */}
        <div className="step-row">
          <div className="step-card">
            <img src={image1} alt="Step 3" className="step-image rounded-corner-image" />
          </div>
          <div className="step-description">
            <h3>Step 3: Adjust and finalize</h3>
            <p>Review and edit the selected questions to ensure everything fits perfectly.</p>
          </div>
        </div>

        {/* Step 4 */}
        <div className="step-row step-row-reverse">
          <div className="step-card">
            <img src={image1} alt="Step 4" className="step-image rounded-corner-image" />
          </div>
          <div className="step-description">
            <h3>Step 4: Publish and share</h3>
            <p>Once ready, publish your exam and share it with students effortlessly.</p>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CreateAssessmentTool;
