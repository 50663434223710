import React, { useRef, useEffect, useCallback, useState } from "react";
import 'katex/dist/katex.min.css';
import Question from "../Question/question";
import InsertQuestionComponent from "../InsertQuestionComponent/InsertQuestionComponent";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Instructions from "../Instructions/Instructions";
import { generatePDF } from '../../../services/generatePDF';
import {
  setDownloadAssessmentPaper,
  setHideComponents,
  setIsAssessmentPaperButtonsVisible,
  setShowMemo,
} from "../../../reducers/componentStates";
import { useDrop } from 'react-dnd';
import "./assessmentPaper.css";
import Swal from "sweetalert2";
import CreateQuestionComponent from '../../../features/createQuestion';
import UpdateQuestionComponent from "../../../features/createQuestion/updateQuestion";
import ExamCover from "../coverPage/ExamCover";

const PAGE_HEIGHT = 1600;

const AssessmentPaper = ({ onEnter, onLeave, total_marks }) => {
  const dispatch = useDispatch();
  const assessment = useSelector(state => state.assessment);
  const assessmentPaper = assessment.assessmentPaper;
  const { currentQuestionIndex, isCreateQuestion, isUpdateQuestion, downloadAssessmentPaper } = useSelector(state => state.componentStates);
  const [questionHeights] = useState({});
  const [numberOfPages, setNumberOfPages] = useState(1);
  const instructions = assessment.instructions;
  const name = assessment.name;
  const droppableRef = useRef(null);
  const containerRef = useRef(null);
  const hideComponents = useSelector(state => state.componentStates.hideComponents);
  const [, refDrop] = useDrop({
    accept: 'QUESTION',
    drop: (item, monitor) => { },
  });

  const [accHeight, setAccHeight] = useState(0);

  useEffect(() => {
    if (accHeight > PAGE_HEIGHT) {
      setAccHeight(0);
      setNumberOfPages(prev => prev + 1);
    }
  }, [accHeight]);

  const updateHeights = (newHeight) => {
    setAccHeight(accHeight + newHeight);
  };

  function handleRemoveQuestion(questionId) {
    // Implementation for handling removal of a question
  }

  // Combine refs function
  const combinedRef = (element) => {
    containerRef.current = element;
    refDrop(element);
  };

  useEffect(() => {
    // Set isAssessmentPaperButtonsVisible to true when the component loads
    dispatch(setIsAssessmentPaperButtonsVisible(true));
  }, [dispatch]);

  useEffect(() => {
    if (droppableRef.current) {
      const { scrollHeight } = droppableRef.current;
      if (scrollHeight > 800) {
      }
    }
  }, [assessmentPaper]);

  const logHtmlString = useCallback(async () => {

    if (containerRef.current && assessmentPaper.length > 0) {
      const result = await Swal.fire({
        title: 'Download Memo',
        text: 'Do you want to download the memo along with the Question paper?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Yes, download with memo',
        cancelButtonText: 'No, just the Question paper',
        showCloseButton: true,
        closeButtonHtml: '&times;',
        focusCancel: true,
      });

      if (result.isConfirmed) {
        const generateSecondPDF = () => {
          setShowMemo(true);
          dispatch(setHideComponents(true));

          setTimeout(() => {
            const htmlString = containerRef.current.outerHTML;
            generatePDF(htmlString, name + '-memo');
            dispatch(setHideComponents(false));
          }, 5);
        };

        dispatch(setHideComponents(true));
        setShowMemo(false);
        setTimeout(() => {
          const htmlString = containerRef.current.outerHTML;
          generatePDF(htmlString, name);
          dispatch(setHideComponents(false));
          generateSecondPDF();
        }, 1);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        setShowMemo(false);
        dispatch(setHideComponents(true));
        setTimeout(() => {
          const htmlString = containerRef.current.outerHTML;
          generatePDF(htmlString, name);
          dispatch(setHideComponents(false));
        }, 0);
      } else {
        console.log('User closed the dialog without downloading');
      }

      dispatch(setDownloadAssessmentPaper(false))
    }
  }, [dispatch, containerRef, name, assessmentPaper]);

  useEffect(() => {
  if ( downloadAssessmentPaper === true ){
    logHtmlString()
  }
    
  }, [downloadAssessmentPaper, logHtmlString]);

return (
  <div className="assessment-paper-wrapper">
    <div
      className={'assessment-paper-document expanded'}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
      ref={combinedRef}
    >
      <div>
        <ExamCover
          testName={assessment.name}
          grade={assessment.grade}
          subject={assessment.subject_name}
          date={assessment.assessment_date}
          totalMarks={total_marks}
        />

        {!(hideComponents && instructions === '') && (<Instructions/>)}
      </div>

      {Array.from({ length: numberOfPages }).map((_, pageIndex) => (
        <React.Fragment key={pageIndex}>
          <div className="assessment-paper-page">
            {assessmentPaper.length === 0 ? (
              !hideComponents && <InsertQuestionComponent index={0} />
            ) : (
              <div>
                {!hideComponents && <InsertQuestionComponent index={0} />}
                {assessmentPaper.map((question, index) => (
                  <Box mt={2} mb={2} key={question.id} style={{
                    marginTop: `${questionHeights[question.id] || 0}px`,
                    transition: 'margin-top 0.3s ease-in-out'
                  }}>
                    {(() => {
                      if (isCreateQuestion && currentQuestionIndex === index) {
                        return <CreateQuestionComponent
                          index={currentQuestionIndex}
                          createNewQuestion={false}
                        />;
                      } else if (isUpdateQuestion && currentQuestionIndex === index) {
                        return <UpdateQuestionComponent index={index} />;
                      } else {
                        return <Question
                          index={index}
                          question={question}
                          isAddedToAssessment={true}
                          isDraggable={false}
                          onRemoveQuestion={handleRemoveQuestion}
                          onUpdateHeight={updateHeights}
                          showTags={true}
                          showAddFromQuestionBank={true}
                        />;
                      }
                    })()}

                    {index < assessmentPaper.length - 1 && !hideComponents &&
                      <InsertQuestionComponent index={index + 1} />}
                  </Box>
                ))}

                {!hideComponents && <InsertQuestionComponent index={assessmentPaper.length} />}
              </div>
            )}
          </div>
          {pageIndex !== numberOfPages - 1 && <div className="grey-space"></div>}
        </React.Fragment>
      ))}
    </div>
  </div>
)}

export default AssessmentPaper;
