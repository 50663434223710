import React from 'react';
import { Typography, Box, Button, Card, CardContent, Grid } from '@mui/material';
import Slider from "react-slick";

import Question1 from '../../../assets/Quesiton1a.png';
import Question2 from '../../../assets/Quesiton2.png';
import Question3 from '../../../assets/Question3.png';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const UploadScriptsInstructions = ({ onStartUpload }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  const textColor = '#494b4e'; // Define the font color globally for the component

  return (
    <div className="instructions-container">
      <Grid container spacing={3}>
        {/* Left section with instructions and button */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom style={{ color: textColor }}>
            How to Upload Scripts for Marking
          </Typography>
          <Typography variant="body1" paragraph style={{ color: textColor }}>
            Follow these steps to upload student scripts for marking:
          </Typography>
          <ul className="instructions-list" style={{ color: textColor }}>
            <li>Click on the "Browse" button to select the scripts from your computer.</li>
            <li>The supported file formats are PDF and image files (.pdf, .jpg, .png).</li>
            <li>Ensure that each script is clearly labeled with the student's name and subject.</li>
            <li>Click the "Upload" button once you've selected all the files.</li>
          </ul>

          <Box className="alert-box" sx={{ mb: 2 }}>
            <Typography variant="h6" className="alert-title" style={{ color: textColor }}>
              Script Upload Free Preview
            </Typography>
            <Typography variant="body2" style={{ color: textColor }}>
              The script upload feature is available for preview in this version. You can upload a limited number of scripts for one class. To unlock unlimited script uploads and advanced marking features, please upgrade to the full version.
            </Typography>
          </Box>

          <Card className="info-card" sx={{ mb: 2 }}>
            <CardContent>
              <Typography variant="h6" style={{ color: textColor }}>
                Important Information
              </Typography>
              <Typography variant="body2" style={{ color: textColor }}>
                Please ensure that the scripts are legible and organized before uploading. Incomplete or unreadable files may result in delayed marking.
              </Typography>
            </CardContent>
          </Card>

          <Box textAlign="left" mt={3}>
            <Button
              variant="contained"
              color="primary"
              className="start-project-button"
              onClick={onStartUpload} // Call onStartUpload function when the button is clicked
            >
              Start Script Upload
            </Button>
          </Box>
        </Grid>

        {/* Right section with image slider, only for larger screens */}
        <Grid item xs={12} md={6} display={{ xs: 'none', md: 'block' }}>
          <Card className="slider-card" elevation={0}>
            <CardContent>
              <Slider {...sliderSettings}>
                <div>
                  <img src={Question1} alt="Question 1" className="slider-image" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div>
                  <img src={Question2} alt="Question 2" className="slider-image" style={{ width: '100%', height: 'auto' }} />
                </div>
                <div>
                  <img src={Question3} alt="Question 3" className="slider-image" style={{ width: '100%', height: 'auto' }} />
                </div>
              </Slider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default UploadScriptsInstructions;
