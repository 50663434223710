import React, { useEffect } from 'react';
import { Button, Typography, Box, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import './TestWelcomePage.css';
import logo from '../../../assets/educase-logo-light.png';
import handwitten from '../../../assets/handwritten.webp';
import fastResults from '../../../assets/fast-results.webp';
import personalisedFeedback from '../../../assets/personalised-feedback.webp';
import { useNavigate, useLocation } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import decrypt from '../../../utils/decrypt';

const TestWelcomePage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const assessmentId = query.get('assessment_id');
    const onlineTestId = query.get('online_test_id');
    const encryptedEmail = query.get('teacher');
    const encryptedExpiryDate = query.get('expiry');
    const encryptedPassPercentage = query.get('passPercentage');
    const navigate = useNavigate();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const signIn = () => {
        navigate(`/test-sign-in`);
    };


    useEffect(() => {
        const email = encryptedEmail ? decrypt(encryptedEmail) : null;
        const expiryDate = encryptedExpiryDate ? decrypt(encryptedExpiryDate) : null;
        const passPercentage = encryptedPassPercentage ? decrypt(encryptedPassPercentage) : null;

        // Store the decrypted values in local storage
        localStorage.setItem("expiryDate", expiryDate);
        localStorage.setItem("teacherEmail", email);
        localStorage.setItem("passPercentage", passPercentage);

        if (email) {
            localStorage.setItem("teacherEmail", email);
        }

        if (expiryDate) {
            localStorage.setItem("expiryDate", expiryDate);
        }

        // Store assessmentId or onlineTestId
        if (assessmentId) {
            localStorage.setItem("assessmentId", assessmentId);
        } else if (onlineTestId) {
            localStorage.setItem("onlineTestId", onlineTestId);
        }
    }, [assessmentId, onlineTestId, encryptedEmail, encryptedExpiryDate, encryptedPassPercentage]);

    return (
        <Box className="test-welcome-page-container">
            <img src={logo} alt="Educase Logo" className="logo" />
            <motion.div
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
            >
                <Typography
                    variant={isSmallScreen ? "h4" : "h3"}
                    component="h1"
                    color="#5F9EA0"
                    marginBottom="1em"
                    gutterBottom
                    fontWeight="900"
                    fontFamily="Indie Flower"
                    fontStyle="normal"
                >
                    Welcome to Educase Test Portal
                </Typography>
            </motion.div>
            {isSmallScreen ? (
                <>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 1, duration: 1 }}
                    >
                        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                            <img src={handwitten} alt='Hand written answer' className='children' />
                            <Typography variant="h5" color="textSecondary" align="center" fontSize={isSmallScreen ? "20" : "30"} paragraph>
                                Submit handwritten answers
                            </Typography>
                        </Box>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 2.5, duration: 1 }}
                    >
                        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                            <img src={fastResults} alt='Fast results' className='children' />
                            <Typography variant="h5" color="textSecondary" align="center" fontSize={isSmallScreen ? "20" : "30"} paragraph>
                                Receive Your Results Same Day
                            </Typography>
                        </Box>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 4, duration: 1 }} // Adjusted delay
                    >
                        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                            <img src={personalisedFeedback} alt='Personalised feedback' className='children' />
                            <Typography variant="h5" color="textSecondary" align="center" fontSize={isSmallScreen ? "20" : "30"} paragraph>
                                Experience Personalised Feedback
                            </Typography>
                        </Box>
                    </motion.div>
                </>
            ) : (
                <>
                    <Grid container alignItems="center" justifyContent="center" spacing={4} style={{ marginBottom: '2em' }}>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 1, duration: 1 }}
                            >
                                <img src={handwitten} alt='Hand written answer' className='children' />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 1.5, duration: 1 }} // Adjusted delay
                            >
                                <Typography variant="h5" color="textSecondary" align="center" fontSize="30" paragraph>
                                    Submit handwritten answers
                                </Typography>
                            </motion.div>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center" spacing={4} style={{ marginBottom: '2em' }}>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 2.5, duration: 1 }} // Adjusted delay
                            >
                                <Typography variant="h5" color="textSecondary" align="center" fontSize="30" paragraph>
                                    Receive Your Results in Just One Hour
                                </Typography>
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 3, duration: 1 }} // Adjusted delay
                            >
                                <img src={fastResults} alt='Fast results' className='children' />
                            </motion.div>
                        </Grid>
                    </Grid> 

                    <Grid container alignItems="center" justifyContent="center" spacing={4} style={{ marginBottom: '2em' }}>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 4, duration: 1 }} // Adjusted delay
                            >
                                <img src={personalisedFeedback} alt='Personalised feedback' className='children' />
                            </motion.div>
                        </Grid>
                        <Grid item>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 4.5, duration: 1 }} // Adjusted delay
                            >
                                <Typography variant="h5" color="textSecondary" align="center" fontSize="30" paragraph>
                                    Experience Personalised Feedback
                                </Typography>
                            </motion.div>
                        </Grid>
                    </Grid>
                </>
            )}
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 5, duration: 1 }}
            >
                <Button variant="contained" className='get-started-test' onClick={signIn}>
                    Get Started
                </Button>
            </motion.div>
        </Box>
    );
};

export default TestWelcomePage;
