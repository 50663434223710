import getConfig from "../../config/index";

const userInfo = localStorage.getItem("user");
const parsedUserInfo = JSON.parse(userInfo || "{}");
const { token } = parsedUserInfo;
const config = getConfig();

export const SubjectApiService = {
    // Fetch all subjects
    async fetchSubjects() {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;

        return await fetch(`${config.api.base_url}/subjects/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`  // Use the updated token
            },
        });
    },

    // Create a new subject
    async createSubject(subjectData) {
        return await fetch(`${config.api.base_url}/subjects/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
            body: JSON.stringify(subjectData),
        });
    },

    // Fetch current user information (to check if the user is a superuser)
    async fetchCurrentUser() {
        return await fetch(`${config.api.base_url}/users/me/`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${token}`,
            },
        });
    }
};

export default SubjectApiService;
