import React, { useState } from 'react';
import { Grid, Box, Button, Drawer, Typography, IconButton, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from "react-redux";
import CreateInstructions from "../CreateInstructions/createInstructions";
import './Instructions.css';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import CreateIcon from '@mui/icons-material/Create';
import {
  isCreateQuestion,
  setCurrentQuestionIndex,
  setRenderCreateQuestionComponent,
} from "../../../reducers/componentStates";
import { addEmptyQuestionAtIndex } from "../../../reducers/assessment";
import { initialQuestionState } from "../../../data/constants/initialQuestionState";
import QuestionBank from "../questionBank/questionBank";

const Instructions = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State to handle drawer open/close
  const assessmentState = useSelector((state) => state.assessment);
  const instructions = assessmentState.instructions;
  const assessmentPaper = assessmentState.assessmentPaper;
  const hideComponents = useSelector(state => state.componentStates.hideComponents);
  const dispatch = useDispatch();
  const paperLength = assessmentPaper.length;

  // Responsive Breakpoints
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const handleCreateQuestion = (index = 0) => {
    dispatch(setCurrentQuestionIndex(index));
    dispatch(addEmptyQuestionAtIndex({ index, initialQuestionState }));
    dispatch(isCreateQuestion(true));
    dispatch(setRenderCreateQuestionComponent(false));
  };

  const handleOpenQuestionBank = () => {
    setIsDrawerOpen(true); // Open the drawer
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false); // Close the drawer
  };

  const handleCreateOrEditInstructionClick = () => {
    setIsEditing(true);
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  // Determine the drawer width based on screen size
  const getDrawerWidth = () => {
    if (isSmallScreen) {
      return '100%'; // Small screen
    } else {
      return '55%'; // Medium screen
    }
  };

  return (
    <div className={'paper-instructions-container'}>
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        {isEditing ? (
          <CreateInstructions onSave={handleSave} />
        ) : (
          <>
            {!hideComponents && (
              <>
                {paperLength < 1 && !instructions && (
                  <>
                    <Grid item>
                      <Button className="outlined-button" onClick={handleCreateOrEditInstructionClick}>
                        <AddIcon style={{ marginRight: '8px' }} />
                        Add Instructions
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button className="outlined-button" onClick={handleOpenQuestionBank}>
                        <LibraryBooksIcon style={{ marginRight: '8px' }} />
                        Open Question Bank
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button className="outlined-button" onClick={handleCreateQuestion}>
                        <CreateIcon style={{ marginRight: '8px' }} />
                        Create Question
                      </Button>
                    </Grid>
                  </>
                )}
                {instructions && !hideComponents && (
                  <Grid container justifyContent="flex-end" alignItems="center">
                    <Box display="flex" justifyContent="flex-end" width="100%">
                      <Button className="filled-button" onClick={handleCreateOrEditInstructionClick}>
                        Edit Instructions
                      </Button>
                    </Box>
                  </Grid>
                )}
                {paperLength >= 1 && !instructions && (
                  <Grid item>
                    <Button className="outlined-button" onClick={handleCreateOrEditInstructionClick}>
                      <AddIcon style={{ marginRight: '8px' }} />
                      Add Instructions
                    </Button>
                  </Grid>
                )}
              </>
            )}
          </>
        )}
      </Grid>
      {!isEditing && instructions && (
        <div className="instruction-text-container">
          <div className="instruction-text" dangerouslySetInnerHTML={{ __html: instructions }} />
        </div>
      )}

      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: getDrawerWidth() // Set responsive width
          }
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2} borderBottom="1px solid #ddd">
          {/* Drawer Header */}
          <Typography variant="h6">Question Bank</Typography>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box p={2}>
          {/* Drawer Body Content */}
          <QuestionBank />
        </Box>
      </Drawer>
    </div>
  );
};

export default Instructions;
