import React from 'react';
import { Box, Typography, Link, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter'; // Use for X (formerly Twitter)
import './Footer.css'; // Add your own styles if needed
import Logo from '../../../assets/educase-logo-light.png'; // Replace with the actual path to your logo

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: '#f0f0f0',
        padding: '20px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center', // Center align all items vertically
        display: 'flex', // Make it a flex container
        flexDirection: 'column', // Stack items vertically
        borderRadius: '0 0 15px 15px',
        marginTop: '20px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '100%',
      }}
    >
      {/* Connect With Us Section */}
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
          color: '#323e48',
          fontSize: { xs: '1.2rem', sm: '1.5rem', md: '1.6rem' }, // Responsive font sizes
        }}
      >
        Connect with us
      </Typography>

      <Typography
        sx={{
          color: '#00b0d4',
          fontSize: { xs: '12px', sm: '14px', md: '16px' }, // Responsive text sizes
          marginBottom: '20px',
          justifyContent: 'center',
        }}
      >
        web: <Link href="https://educasetools.com" target="_blank" sx={{ color: '#00b0d4', textDecoration: 'none' }}>educasetools.com</Link> &nbsp;
        email: <Link href="mailto:support@educasetools.com" sx={{ color: '#00b0d4', textDecoration: 'none' }}>support@educasetools.com</Link>
      </Typography>

      {/* Social Icons */}
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginBottom: '20px' }}>
        <IconButton href="https://www.linkedin.com" target="_blank">
          <LinkedInIcon sx={{ color: '#0A66C2', fontSize: { xs: '20px', sm: '24px', md: '28px' } }} /> {/* Responsive icon size */}
        </IconButton>
        <IconButton href="https://www.instagram.com" target="_blank">
          <InstagramIcon sx={{ color: '#E1306C', fontSize: { xs: '20px', sm: '24px', md: '28px' } }} /> {/* Responsive icon size */}
        </IconButton>
        <IconButton href="https://www.facebook.com" target="_blank">
          <FacebookIcon sx={{ color: '#1877F2', fontSize: { xs: '20px', sm: '24px', md: '28px' } }} /> {/* Responsive icon size */}
        </IconButton>
        <IconButton href="https://www.twitter.com" target="_blank">
          <TwitterIcon sx={{ color: '#1DA1F2', fontSize: { xs: '20px', sm: '24px', md: '28px' } }} /> {/* Responsive icon size */}
        </IconButton>
      </Box>

      {/* Logo Section */}
      <Box sx={{ marginBottom: '10px', display: 'flex', justifyContent: 'center' }}>
        <img
          src={Logo}
          alt="Logo"
          style={{
            maxHeight: '40px',
            height: 'auto',
            maxWidth: '100%',
          }}
          sx={{
            maxHeight: { xs: '40px', sm: '50px', md: '60px' }, // Responsive logo size
          }}
        />
      </Box>

      {/* Copyright Section */}
      <Typography
        sx={{
          fontSize: { xs: '12px', sm: '14px', md: '16px' }, // Responsive copyright text size
          color: '#A0A0A0',
        }}
      >
        Copyright © 2024 Educase
      </Typography>
    </Box>
  );
};

export default Footer;
