import React, { useEffect, useState } from "react";
import { Card, CardContent, Box, Button, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import '../OnboardingPage.css';

const SchoolOnboarding = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            const user = JSON.parse(storedUser);  // Parse the string into an object
            setEmail(user.email || '');  // Set the email from the user object
        }
    }, []);

    const handleHomeClick = () => navigate('/home');

    return (
        <div className="onboarding-card-container">
            <Card
                className="onboarding-card-educase"
                sx={{
                    borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: { xs: '5px', sm: '30px', md: '40px' },
                    width: { xs: '100vw', sm: '100vw', md: '80vw' },
                    maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                    margin: { xs: '0px', sm: '30px', md: '40px' },
                }}
            >
                <CardContent className="card-content-educase">
                    <Typography
                        variant="h4"
                        sx={{
                            color: '#5F9EA0',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginBottom: 4,
                        }}
                    >
                        Thank You for Reaching Out!
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: '#777f86',
                            textAlign: 'center',
                            fontSize: '1.2rem',
                            marginBottom: 4,
                        }}
                    >
                        We are excited to begin this journey with you and your school! We’ll be in touch within the next 2 days to discuss the onboarding process and help you get started.
                    </Typography>

                    <Typography
                        variant="body1"
                        sx={{
                            color: '#777f86',
                            textAlign: 'center',
                            fontSize: '1.1rem',
                            marginBottom: 4,
                        }}
                    >
                        You can now continue using the platform as an admin. If you have any questions, feel free to reach out to us!
                    </Typography>

                    <Box mt={4} display="flex" justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={handleHomeClick}
                            sx={{
                                backgroundColor: "primary",
                                color: "white",
                                width: "150px",
                                fontSize: "16px",
                                borderRadius: "16px"
                            }}
                        >
                            Go to Home
                        </Button>
                    </Box>

                    <Box sx={{ mt: 4 }}>
                        <Typography variant="body3" sx={{ color: '#777', textAlign: 'center' }}>
                            You're signed in as {email}. <a href="/signout" style={{
                            color: '#31c0e8',
                            textDecoration: 'none',
                            fontSize: '13px'
                        }}>Sign out</a>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default SchoolOnboarding;
