import { Box, List, ListItem, ListItemText, Collapse } from '@mui/material';
import React, { useState } from 'react';
import Rubric from './Rubric/Rubric';
import AdobePDFViewer from './AdobePDFViewer/AdobePDFViewer';

const MarkingMenu = ({ student, questions, pdfUrl }) => {
  const [expandedQuestionIndex, setExpandedQuestionIndex] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const handleQuestionClick = (question, index) => {
    setExpandedQuestionIndex(expandedQuestionIndex === index ? null : index);
    setSelectedQuestion(selectedQuestion === question ? null : question);
  };

  return (
    <div className="marking-menu-container">
      <Box className="marking-menu-content" display="flex" height="100%">
        {/* Left side - Question List */}
        <Box className="marking-menu-left" width="40%" borderRight="1px solid #ccc" padding={2}>
          <List>
            {questions.assessmentPaper.map((question, index) => (
              <div key={index}>
                {/* List Item for the Question */}
                <ListItem onClick={() => handleQuestionClick(question, index)}>
                  <ListItemText primary={`Question ${index + 1}`} />
                </ListItem>

                {/* Collapsible Rubric and Question Content */}
                <Collapse in={expandedQuestionIndex === index} timeout="auto" unmountOnExit>
                  <Box padding={2}>
                    {selectedQuestion && (
                      <Rubric question={selectedQuestion} index={index} />
                    )}
                  </Box>
                </Collapse>
              </div>
            ))}
          </List>
        </Box>

        {/* Right side - Adobe PDF */}
        <Box className="marking-menu-right" width="60%" padding={2} display="flex" flexDirection="column">
          {/* PDF Viewer */}
          <Box flex="1" marginTop={2}>
            <AdobePDFViewer />
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MarkingMenu;