import React, { useState } from "react";
import ClassList from "./ClassList/ClassList";
import { useSelector } from "react-redux";
import MarkingMenu from "./MarkingMenu/MarkingMenu";
import AdobePDFViewer from "./MarkingMenu/AdobePDFViewer/AdobePDFViewer";
import FinalPreview from "./FinalPreview/FinalPreview";

const students = [
    { 
        name: "Thabo", 
        lastname: "Mokoena", 
        avatar: "", 
        testDate: "2024-09-25", 
        progress: 50, 
        finalMark: null 
    },
    { 
        name: "Ayanda", 
        lastname: "Ndlovu", 
        avatar: "", 
        testDate: "2024-09-26", 
        progress: 80, 
        finalMark: 72 
    },
    { 
        name: "Lerato", 
        lastname: "Sithole", 
        avatar: "", 
        testDate: "2024-09-27", 
        progress: 60, 
        finalMark: null 
    },
    { 
        name: "Sibongile", 
        lastname: "Khumalo", 
        avatar: "", 
        testDate: "2024-09-28", 
        progress: 90, 
        finalMark: 85 
    },
    { 
        name: "Mandla", 
        lastname: "Ngcobo", 
        avatar: "", 
        testDate: "2024-09-29", 
        progress: 100, 
        finalMark: 90 
    },
    { 
        name: "Nomvula", 
        lastname: "Dlamini", 
        avatar: "", 
        testDate: "2024-09-30", 
        progress: 70, 
        finalMark: 65 
    },
    { 
        name: "Tsepo", 
        lastname: "Nkosi", 
        avatar: "", 
        testDate: "2024-09-30", 
        progress: 40, 
        finalMark: null 
    },
    { 
        name: "Zanele", 
        lastname: "Sibanyoni", 
        avatar: "", 
        testDate: "2024-09-24", 
        progress: 75, 
        finalMark: 68 
    },
    { 
        name: "Bongani", 
        lastname: "Mzimba", 
        avatar: "", 
        testDate: "2024-09-23", 
        progress: 85, 
        finalMark: 78 
    },
    { 
        name: "Khanya", 
        lastname: "Mpofu", 
        avatar: "", 
        testDate: "2024-09-22", 
        progress: 95, 
        finalMark: 88 
    }
];

const ManualMarking = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [, setPdfUrl] = useState("");
    const [questions] = useState(useSelector((state) => state.assessment));

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleSelectStudent = (student) => {
        setSelectedStudent(student);
        setPdfUrl(`https://example.com/${student.name}-exam-paper.pdf`);
        handleNext();
    };

    return (
        <div className="manual-marking-container">
            <div>
                    <div>
                        <div className="step-content">
                            {activeStep === 0 && (
                                <ClassList
                                    studentsData={students}
                                    onSelectStudent={handleSelectStudent}
                                />
                            )}
                            {activeStep === 1 && selectedStudent && (
                                <div className="marking-content">
                                    <MarkingMenu
                                        student={selectedStudent}
                                        questions={questions}
                                    />
                                    <AdobePDFViewer />
                                </div>
                            )}
                            {activeStep === 2 && <FinalPreview />}
                        </div>
                    </div>
            </div>
        </div>
    );
};

export default ManualMarking;