import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isOpen: false,
    removeAddQuestion: false,
    scrollCreateQuestion: false,
    hideComponents: false,
    inReview: false,
    questionType: null,
    isCreateQuestionVisible: true,
    isMobileNavBarVisible: false,
    isAssessmentPaperButtonsVisible : false,
    showMemo : true,
    downloadAssessmentPaper: false
};

const componentStatesSlice = createSlice({
    name: "componentStates",
    initialState,
    reducers: {
        setHideComponents: (state, action) => {
            state.hideComponents = action.payload;
        },
        setCurrentQuestionIndex: (state, action) => {
            state.currentQuestionIndex = action.payload;
        },
        resetState: (state) => {
            Object.assign(state, initialState);
        },
        isCreateQuestion: (state, action) => {
            state.isCreateQuestion = action.payload;
        },
        isUpdateQuestion: (state, action) => {
            state.isUpdateQuestion = action.payload;
        },
        setRenderCreateQuestionComponent: (state, action) => {
            state.renderCreateQuestionComponent = action.payload;
        },
        setQuestionType: (state, action) => {
            state.questionType = action.payload;
        },
        setIsCreateQuestionVisible: (state, action) => {
            state.isCreateQuestionVisible = action.payload;
        },
        setMobileIsNavBarVisible: (state, action) => {
            state.isMobileNavBarVisible = action.payload;
        },
        setIsAssessmentPaperButtonsVisible: (state, action) => {
            state.isAssessmentPaperButtonsVisible = action.payload;
        },
         setShowMemo: (state, action) => {
            state.showMemo = action.payload;
        },
        setDownloadAssessmentPaper: (state, action) => {
            state.downloadAssessmentPaper = action.payload;
        },
    }
});

export const { 
    setHideComponents,
    setCurrentQuestionIndex,
    resetState,
    isCreateQuestion,
    isUpdateQuestion,
    setRenderCreateQuestionComponent,
    setQuestionType,
    setIsCreateQuestionVisible,
    setMobileIsNavBarVisible,
    setIsAssessmentPaperButtonsVisible,
    setShowMemo,
    setDownloadAssessmentPaper
} = componentStatesSlice.actions;

export default componentStatesSlice.reducer;
