import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import './ScriptDetails.css';
import CourseApiService from "../../../services/api/courseAPIService";
import AssessmentApiService from "../../../services/api/assessmentAPIService"; // Adjust the path accordingly

const ScriptDetails = ({ handleFileUpload, selectedFile }) => {
  const [examId, setExamId] = useState(''); // State to hold selected exam ID
  const [instructions, setInstructions] = useState('');
  const [markingObjectives, setMarkingObjectives] = useState(''); // State to hold marking objectives
  const [courses, setCourses] = useState([]); // State to hold courses
  const [exams, setExams] = useState([]); // State to hold exams
  const [selectedCourseId, setSelectedCourseId] = useState(''); // State to hold selected course ID
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await CourseApiService.getCoursesForTeacher(token);
        if (response.status === 200) {
          const coursesData = await response.json();
          setCourses(coursesData);
        } else {
          console.error("Failed to fetch courses.");
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [token]);

  const fetchExamsForCourse = async (courseId) => {
    try {
      const response = await AssessmentApiService.fetchExamsForCourse(courseId, token);
      if (response) {
        setExams(response);
      } else {
        console.error("Failed to fetch exams. Status:", response.status);
      }
    } catch (error) {
      console.error("Error fetching exams:", error);
    }
  };

  const handleCourseChange = (e) => {
    const selectedCourseId = e.target.value;
    setSelectedCourseId(selectedCourseId); // Store the selected course ID
    setExamId(''); // Reset the exam ID when a new course is selected
    fetchExamsForCourse(selectedCourseId); // Fetch exams for the selected course
  };

  const handleUploadScripts = () => {
    // Navigate to the UploadScripts component with the selected course ID
    navigate(`/upload-learner/${selectedCourseId}`);
  };

  // Styles for text fields
  const textFieldStyles = {
    '& .MuiOutlinedInput-root': {
      borderRadius: '8px',
      borderColor: '#5F9EA0',
      '& fieldset': {
        borderColor: '#5F9EA0',
      },
      '&:hover fieldset': {
        borderColor: '#5F9EA0',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#5F9EA0',
      },
    },
    '& .MuiInputBase-input': {
      padding: '12px 15px',
      color: '#7aa8bc',
    },
    '& .MuiInputLabel-root': {
      color: '#7aa8bc',
    },
  };

  return (
    <div className={"marking-container"}>
      <p className="marking-project-title">Start a new marking project</p>
      <p className="instructions-text">
        If you'd like a tutorial of this feature, please see the
        <a href="your-video-link-here" target="_blank" rel="noopener noreferrer" className="instructions-link">
          How-to video
        </a>.
      </p>

      <Box mt={2}>
        <p className="section-title">1. Choose Course</p>
        <TextField
          select
          fullWidth
          onChange={handleCourseChange} // Call the function when course changes
          variant="outlined"
          placeholder="Select a course"
          sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
        >
          {courses.map((course) => (
            <MenuItem key={course.id} value={course.id} style={{ color: '#323e48' }}>
              {course.name}  {/* Assuming course has a name property */}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box mt={2}>
        <p className="section-title">2. Choose Assessment Paper</p>
        <TextField
          select
          fullWidth
          value={examId}
          onChange={(e) => setExamId(e.target.value)} // Update the selected exam ID
          variant="outlined"
          placeholder="Select an assessment paper"
          sx={{ height: '35px', '& .MuiInputBase-root': { height: '35px' } }}
        >
          {exams.map((exam) => (
            <MenuItem key={exam.id} value={exam.id} style={{ color: '#323e48' }}>
              {exam.name}  {/* Use the name from the fetched exam data */}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {/* 3. Marking Objectives Input */}
      <Box mt={2}>
        <p className="section-title">3. Marking Objectives</p>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={markingObjectives}
          onChange={(e) => setMarkingObjectives(e.target.value)}
          variant="outlined"
          placeholder="Type the marking objectives here"
          sx={textFieldStyles}
        />
      </Box>

      {/* 4. Instructions Input */}
      <Box mt={2}>
        <p className="section-title">4. Instructions for Marking</p>
        <TextField
          fullWidth
          multiline
          rows={4}
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          variant="outlined"
          placeholder="Type the instructions for marking"
          sx={textFieldStyles}
        />
      </Box>

      {/* Button at the bottom right inside the card */}
      <Box className="button-section">
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadScripts} // Call the upload handler
        >
          Upload Scripts
        </Button>
        {selectedFile && (
          <p className="selected-file-text">
            Selected file: {selectedFile.name}
          </p>
        )}
      </Box>
    </div>
  );
};

export default ScriptDetails;
