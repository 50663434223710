import React, { useState } from 'react';
import { Card, CardContent, Typography, Grid, Switch, FormControlLabel, Box } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const TopicsStats = ({ topics }) => {
  const [view, setView] = useState('passRate');

  const passRateData = topics.map(topic => {
    const totalLearners = topic.learners.length;
    const passCount = topic.learners.filter(learner => learner.passed).length;
    const failCount = topic.learners.filter(learner => !learner.passed).length;

    return {
      name: topic.name,
      Pass: Math.round((passCount / totalLearners) * 100 * 100) / 100,
      Fail: Math.round((failCount / totalLearners) * 100 * 100) / 100,
      passCount: passCount,
      failCount: failCount,
    };
  });

  const sortedTopicsByPassRate = [...passRateData].sort((a, b) => b.Pass - a.Pass);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip" style={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
          <p className="label" style={{ fontWeight: 'bold', marginBottom: '5px' }}>{`${label}`}</p>
          <p className="intro" style={{ color: '#4CAF50', fontWeight: 'bold', marginBottom: '5px' }}>{`Pass: ${payload[0].value}% (${payload[0].payload.passCount} learners)`}</p>
          <p className="intro" style={{ color: '#ba5a53', fontWeight: 'bold', marginBottom: '5px' }}>{`Fail: ${payload[1].value}% (${payload[1].payload.failCount} learners)`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card style={{ margin: '10px 20px', boxShadow: '0px 4px 20px 4px rgba(0, 0, 0, 0.2)' }}>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <FormControlLabel
            control={
              <Switch
                checked={view === 'horizontalBarChart'}
                onChange={(e) => setView(e.target.checked ? 'horizontalBarChart' : 'passRate')}
                name="viewToggle"
                color="primary"
              />
            }
            label={`Switch to ${view === 'horizontalBarChart' ? 'Pass Rate' : 'Bar Chart'}`}
          />
        </Box>
        <Grid container spacing={4} style={{ marginTop: 20 }}>
          {view === 'passRate' && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Pass Rate per Topic</Typography>
              <Typography variant="body2" gutterBottom>
                This bar chart shows the pass rate for each topic.
              </Typography>
              <BarChart width={1000} height={300} data={passRateData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="Pass" fill="#5abcb5" />
                <Bar dataKey="Fail" fill="#895bbd" />
              </BarChart>
            </Grid>
          )}
          {view === 'horizontalBarChart' && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom>Best to Worst Performing Topics</Typography>
              <Typography variant="body2" gutterBottom>
                This horizontal bar chart shows the topics sorted from best to worst performing.
              </Typography>
              <BarChart width={1000} height={300} data={sortedTopicsByPassRate} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip content={<CustomTooltip />} />
                <Legend />
                <Bar dataKey="Pass" fill="#80E0B1" />
                <Bar dataKey="Fail" fill="#F08080" />
              </BarChart>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopicsStats;
