import React from 'react';
import { useSelector } from "react-redux";
import 'katex/dist/katex.min.css';
import NestedSubQuestion from "../nestedSubQuestion/nestedSubQuestion";
import "./Subquestion.css";
import DisplayContent from "../../displayContent/displayContent";

const SubQuestion = ({ subQuestion, questionIndex, subIndex, showMemo, showTags }) => {

  const hideComponents = useSelector(state => state.componentStates.hideComponents);
  questionIndex = questionIndex + 1;
  const questionNumber = `${questionIndex}.${subIndex}`;
  return (
    !subQuestion.nested_sub_questions || subQuestion.nested_sub_questions.length === 0 ? (
      <ol className="sub-question">
        <li className="sub-question-item">
          <div className="sub-question-content">
            <span className="sub-question-number">{questionNumber}</span>
            <DisplayContent content={subQuestion.text}></DisplayContent>
            <span className="sub-question-marks">
              ({subQuestion.marks || ""})
            </span>
            <br />
          </div>

          {subQuestion.sub_question_memo && showMemo && (
            <div>
              <span className="answer-label">ANSWER</span>
              <br />
              <DisplayContent content={subQuestion?.sub_question_memo?.text}></DisplayContent>
            </div>
          )}

          <br />
          {!hideComponents && showTags && (
            <div style={{
              display: "flex",
              justifyContent: "flex-start",
              color: "#5F9EA0",
              fontSize: "12px",
              marginTop: "5px"

            }}>
              {(() => {
                const items = [];

                if (subQuestion.level) {
                  items.push(subQuestion.level);
                }

                if (subQuestion.topic && subQuestion.topic.name) {
                  items.push(subQuestion.topic.name);
                }

                if (subQuestion.nature) {
                  items.push(subQuestion.nature);
                }

                if (subQuestion.difficulty) {
                  items.push(subQuestion.difficulty);
                }

                if (subQuestion.source) {
                  items.push(subQuestion.source);
                }

                if (subQuestion.year) {
                  items.push(subQuestion.year);
                }
                return items.join(' • ');
              })()}
            </div>
          )}
        </li>
      </ol>
    ) : (
      <>
        <div className={"sub-question-content"}>

          <span className="sub-question-number">{questionNumber}</span>
          <DisplayContent content={subQuestion.text}></DisplayContent>
        </div>
        {
          subQuestion.nested_sub_questions.map((nestedSubQuestion, nestedIndex) => (
            <NestedSubQuestion
              nestedSubQuestion={nestedSubQuestion}
              nestedIndex={nestedIndex}
              questionIndex={questionIndex}
              subIndex={subIndex}
              showMemo={showMemo}
              showTags={showTags}
            />
          ))
        }
      </>
    )
  );
};

export default SubQuestion;