import React, { useState, useRef, useEffect } from 'react';
import "./AssessmentContainer.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Question from '../../../components/CreateAssessment/Question/question';
import AssessmentApiService from '../../../services/api/assessmentAPIService';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import TestHeading from '../TestHeadingComponent/TestHeading';

const AssessmentContainer = () => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questionsData, setQuestionsData] = useState(null)
    ;
    const [isLoading, setIsLoading] = useState(false);
    const [answerImageUrls, setAnswerImageUrls] = useState({});
    const location = useLocation();
    const navigate = useNavigate();
    const questionHtmlRef = useRef(null);

    const query = new URLSearchParams(location.search);
    const assessmentId = query.get('assessment_id');
    const onlineTestId = query.get('online_test_id');
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");

    const { token } = parsedUserInfo;

    useEffect(() => {
        const fetchData = async () => {
            if (assessmentId && token) {
                setIsLoading(true);
                try {
                    const data = await AssessmentApiService.fetchAssessment(assessmentId, token);
                    setQuestionsData(data);
                } catch (error) {
                    console.error('Error fetching assessment data:', error);
                }
                setIsLoading(false);
            }
        };
        fetchData();
    }, [assessmentId, token]);

    useEffect(() => {
        const storedImageUrls = JSON.parse(sessionStorage.getItem('answerImageUrls')) || {};
        setAnswerImageUrls(storedImageUrls);
    }, []);

    useEffect(() => {
        sessionStorage.setItem('answerImageUrls', JSON.stringify(answerImageUrls));
    }, [answerImageUrls]);

    const goToNextQuestion = () => {
        if (questionsData && currentQuestionIndex < questionsData.questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            navigate('/upload-answers', { state: { questionsData, answerImageUrls, onlineTestId } });
        }
    };

    const goToPreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const currentQuestion = questionsData ? questionsData.questions[currentQuestionIndex] : null;

    if (isLoading || !questionsData) {
        return (
            <div className="assessment-container-loading-screen">
                <h2 className='assessment-container-heading'>Good luck!</h2>
                    <CircularProgress className='assessment-container-loading' style={{ color: 'white' }} />
            </div>
        );
    } else {
        return (
            <div className="assessmentContainer">
                <TestHeading
                    testName={questionsData.name}
                    subjectName={questionsData.subject.name}
                    grade={questionsData.grade}
                />
                <div className="questionContainer">
                    <div ref={questionHtmlRef}>
                        {currentQuestion && (
                            <Question
                                question={currentQuestion}
                                index={currentQuestionIndex}
                                showAddFromQuestionBank={false}
                            />
                        )}
                    </div>
                </div>
                <div className="navigationButtons">
                    <Button
                        className="previousQuestionButton"
                        onClick={goToPreviousQuestion}
                        disabled={currentQuestionIndex === 0}
                    >
                        Previous Question
                    </Button>
                    <Button
                        className="nextQuestionButton"
                        onClick={goToNextQuestion}
                    >
                        {currentQuestionIndex === questionsData.questions.length - 1 ? 'Finish Test' : 'Next Question'}
                    </Button>
                </div>
            </div>
        );
    }
};

export default AssessmentContainer;