import React, { useEffect } from "react";
import "./AdobePDFViewer.css";

const AdobePDFViewer = () => {
  const pdfUrl = "https://assets.educasetools.com/Baseline%20Test%20Grade%204.pdf";

  useEffect(() => {
    const adobeDCView = new window.AdobeDC.View({
      clientId: "252d2b55da9e41238c7ecea89f59eefb",
      divId: "adobe-pdf-viewer",
    });

    // Embed the PDF file
    var previewFilePromise = adobeDCView.previewFile({
      content: {
        location: {
          url: pdfUrl,
        },
      },
      metaData: {
        fileName: "sample.pdf",
        id: "77c6fa5d-6d74-4104-8349-657c8411a834"
      },
    }, {
      embedMode: "FULL_WINDOW",
      enableAnnotationAPIs: true,
      includePDFAnnotations: true,
      downloadWithAnnotations: true,
      showAnnotationTools: true,
    });

    previewFilePromise.then(adobeViewer => {
      adobeViewer.getAnnotationManager().then(annotationManager => {
        // Start annotation mode (e.g., shape or freetext) with custom options
        const annotationOptions = {
          defaultColor: "#FF0000",
          cursor: "crosshair",
          strokeWidth: 2
        };

        // Example: Start drawing shape annotations
        const annotationMode = "shape";

        // Start the annotation with the given mode and options
        annotationManager.startAnnotationMode(annotationMode, annotationOptions)
          .then(result => {
            console.log("Annotation mode started:", result);
          })
          .catch(error => {
            console.error("Error starting annotation mode:", error);
          });
      });
    });
  }, [pdfUrl]);

  return (
    <div id="adobe-pdf-viewer" className="adobePDFViewerContainer"></div>
  );
};

export default AdobePDFViewer;