import React, { useState, useEffect } from "react";
import DisplayContent from "../../../../../components/displayContent/displayContent";
import "./Rubric.css";

const Rubric = ({ question, index }) => {
  const [totalMarks, setTotalMarks] = useState(question.marks || 0);
  const [subQuestionMarks, setSubQuestionMarks] = useState(question.sub_questions?.map(sub => sub.marks || 0) || []);
  const [nestedSubQuestionMarks] = useState(question.sub_questions?.map(sub => sub.nested_sub_questions?.map(nested => nested.marks || 0) || []) || []);

  useEffect(() => {
      const calculateTotalMarks = () => {
    let total = 0;
    subQuestionMarks.forEach((subMark, subIndex) => {
      total += parseFloat(subMark || 0);
      nestedSubQuestionMarks[subIndex]?.forEach(nestedMark => {
        total += parseFloat(nestedMark || 0);
      });
    });
    return total;
  };
    const newTotalMarks = calculateTotalMarks();
    setTotalMarks(newTotalMarks);
  }, [subQuestionMarks, nestedSubQuestionMarks]);

  const handleSubQuestionMarksChange = (event, subIndex) => {
    const inputMarks = parseFloat(event.target.value || 0);
    const maxSubMarks = parseFloat(question.sub_questions[subIndex].marks || 0);

    if (inputMarks <= maxSubMarks) {
      const newMarks = [...subQuestionMarks];
      newMarks[subIndex] = inputMarks;
      setSubQuestionMarks(newMarks);
    } else {
      alert(`Sub-question ${index + 1}.${subIndex + 1} cannot exceed ${maxSubMarks} marks.`);
    }
  };

  return (
    <div className="rubric-container">
      <h3 className="question-headline">QUESTION {index + 1}</h3>
      <div className="total-marks">Total Marks: {totalMarks}</div>
      {question.instructions && (
        <div className="question-instructions">
          <DisplayContent content={question.instructions} />
        </div>
      )}

      {question.sub_questions?.map((subQuestion, subQuestionIndex) => (
        <div key={subQuestionIndex} className="sub-question-container">
          <label> {index + 1}.{subQuestionIndex + 1}</label>
          <DisplayContent content={subQuestion.text} />

          <input
            type="number"
            value={subQuestionMarks[subQuestionIndex]}
            onChange={(e) => handleSubQuestionMarksChange(e, subQuestionIndex)}
            className="marks-input-overlay-box"
            placeholder={`Max: ${subQuestion.marks}`}
          />
        </div>
      ))}
    </div>
  );
};

export default Rubric;