import React, { useState } from 'react';
import { Card } from '@mui/material';
import UploadScriptsInstructions from './Instructions/uploadScriptsInstructions';
import ScriptDetails from "./ScriptsDetails/ScriptDetails"; // Import the ScriptDetails component

const UploadScripts = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStarted, setUploadStarted] = useState(false); // State to track if the upload has started

  const handleFileUpload = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = () => {
    if (selectedFile) {
      // Handle file submission here (e.g., send it to the server)
      console.log('File submitted:', selectedFile);
    } else {
      console.log('No file selected');
    }
  };

  const startScriptUpload = () => {
    setUploadStarted(true); // Set uploadStarted to true when the button is clicked
  };

  return (
    <div className="uploading-container">
      <Card
        className={uploadStarted ? 'script-details-card' : 'upload-instructions-card'}
        elevation={0}
        sx={{ height: 'auto', overflow: 'hidden' }} // Ensure height is auto and prevent overflow
      >
        {uploadStarted ? (
          <ScriptDetails
            handleFileUpload={handleFileUpload}
            selectedFile={selectedFile}
            handleSubmit={handleSubmit}
          />
        ) : (
          <UploadScriptsInstructions onStartUpload={startScriptUpload} />
        )}
      </Card>
    </div>
  );
};

export default UploadScripts;
