import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import Question from "../Question/question";
import QuestionApiService from "../../../services/api/questionAPIService";
import CircularProgress from '@mui/material/CircularProgress';
import Button from "@mui/material/Button";
import "./questionBank.css";
import Swal from 'sweetalert2';

const QuestionBank = () => {
    // Filter questions by the selected filter options
    const [filteredQuestions, setFilteredQuestions] = React.useState([]);
    const [questionData, setQuestionData] = React.useState([]); //Gets populated when returning questions from the backend
    const assessmentState = useSelector((state) => state.assessment);
    const assessmentPaper = assessmentState.assessmentPaper
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [totalCount, setTotalCount] = useState(0);
    const [currentCount, setCurrentCount] = useState(0);

    const selectedTopics = useSelector((state) => state.selectedTopics);

    function handleRemoveQuestion(questionId) {
        setFilteredQuestions(prevQuestions =>
            prevQuestions.filter(question => question.id !== questionId)
        );
    }

    const [marksFilterValue] = useState("all");
    const [difficultyFilterValue] = useState("all");
    const [natureFilterValue ] = useState("all");
    const [levelFilterValue] = useState("all");
    const [sourceFilterValue] = useState("all");
    const [yearFilterValue] = useState("all");

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    useEffect(() => {
        // This useEffect will filter questions, subquestions, and nested subquestions.

        const filterAppliedQuestions = questionData.filter((question) => {
            // Filter check for the main Question
            let questionCheck = checkQuestionProperties(question);
            // Filter check for subquestions
            let subQuestionsCheck = question.sub_questions?.some(subQuestion => {
                let subQuestionCheck = checkQuestionProperties(subQuestion);
                // Filter check for nested subquestions
                let nestedSubQuestionsCheck = subQuestion.nested_sub_questions?.some(nestedSubQuestion => {
                    return checkQuestionProperties(nestedSubQuestion);
                });

                return subQuestionCheck || (nestedSubQuestionsCheck ?? false); // Change to `some` and `||`
            });

            return questionCheck || (subQuestionsCheck ?? false); // Change to `some` and `||`
        });

        setFilteredQuestions(filterAppliedQuestions);

    }, [questionData, marksFilterValue, difficultyFilterValue, natureFilterValue, levelFilterValue, sourceFilterValue, yearFilterValue]);

    // Helper function to check Question properties against filter values
    function checkQuestionProperties(question) {
        let markCheck = marksFilterValue === "all" || question.marks === marksFilterValue;
        let difficultyCheck = difficultyFilterValue === "all" || question.difficulty === difficultyFilterValue;
        let natureCheck = natureFilterValue === "all" || question.nature === natureFilterValue;
        let levelCheck = levelFilterValue === "all" || question.level === levelFilterValue;
        let sourceCheck = sourceFilterValue === "all" || question.source === sourceFilterValue;
        let yearCheck = yearFilterValue === "all" || String(question.year) === String(yearFilterValue);

        return markCheck && difficultyCheck && natureCheck && levelCheck && sourceCheck && yearCheck;
    }

    const extractTopicIds = (selectedTopicsState) => {
        return selectedTopicsState.reduce((acc, item) => {
            if (Array.isArray(item) && item.topics) {
                return [...acc, ...item.topics.flatMap(innerItem => innerItem.id ? [innerItem.id] : [])];
            } else if (item.topic && item.topic.id) {
                return [...acc, item.topic.id];
            }
            return acc;
        }, []);
    };

    const style = {
        height: "85vh",
        backgroundColor: "#ffffff",
        transition: "width 0.3s ease-in-out",
        overflowY: "auto",
    };

    const fetchQuestions = async (page) => {
        setIsLoading(true);
        const topic_ids = extractTopicIds(selectedTopics);

        if (hasMore) {
            try {
                const response = await QuestionApiService.fetchQuestions(assessmentState.grade, assessmentState.subject, topic_ids, page);
                if (response.ok) {
                    const fetchedData = await response.json();

                    // Filter out questions that are in the assessment paper
                    const newQuestionsNotInPaper = fetchedData.questions.filter(q => !assessmentPaper.some(ap => ap.id === q.id));

                    setQuestionData(prevQuestions => [
                        ...prevQuestions,
                        ...newQuestionsNotInPaper
                    ]);

                    setHasMore(fetchedData.has_more);

                    // Adjust the total count based on the questions not in the paper
                    setTotalCount(fetchedData.total_count - assessmentPaper.length);
                    setCurrentCount(newQuestionsNotInPaper.length);
                } else {
                    const errorData = await response.json();
                    console.error("Error fetching questions:", errorData);
                }
            } catch (error) {
                console.error("Error loading questions:", error);
            } finally {
                setIsLoading(false);
            }
        } else {
            Toast.fire({
                icon: 'info',
                title: 'You have reached the end of the list.'
            });
        }
    };

    const loadMoreQuestions = () => {
        setCurrentPage(prev => prev + 1);
    };
    useEffect(() => {
        // This effect will run whenever the assessmentPaper changes
        const questionIdsInPaper = new Set(assessmentPaper.map(q => q.id));

        // Filter the questionData to exclude questions that are in the assessment paper
        const questionsNotInPaper = questionData.filter(q => !questionIdsInPaper.has(q.id));

        // Update the filteredQuestions state with the questions not in the paper
        setFilteredQuestions(questionsNotInPaper);

        // Update the currentCount state with the number of questions not in the paper
        setCurrentCount(questionsNotInPaper.length);

        // You don't need to update totalCount here because it should be set
        // when you fetch new questions from the backend.
    }, [assessmentPaper, questionData]);


    useEffect(() => {
        if (currentPage > 0) {
            fetchQuestions(currentPage);
        }
    }, [currentPage, assessmentState.grade, assessmentState.subject, selectedTopics]);

    return (
        <div style={style}>
            <div>
                {isLoading ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '50%' }}>
                        <CircularProgress />
                    </div>
                    : (
                        <Nav className="flex-column">
                            {!!filteredQuestions &&
                                filteredQuestions.map((question, index) => (
                                    <div style={{ borderBottom: '1px solid #ccc', padding: '10px 0' }} key={question.id}>
                                        <Question
                                            question={question}
                                            index={index}
                                            isAddedToAssessment={false}
                                            onRemoveQuestion={handleRemoveQuestion}
                                            showTags={true}
                                            showAddFromQuestionBank={true}
                                        />
                                    </div>
                                ))}

                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                <p>{currentCount} of {totalCount} questions</p>
                            </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingBottom: "100px",
                                }}
                            >
                                <div style={{ textAlign: "center", padding: "20px" }}>
                                    {hasMore && (
                                        <Button onClick={loadMoreQuestions}>
                                            Load more questions
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </Nav>
                    )}
            </div>
        </div>
    );

};
export default QuestionBank;
