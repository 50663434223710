import React, { useState } from 'react';
import { Grid, Box, Typography, IconButton, Drawer, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentQuestionIndex,
  isCreateQuestion,
  setRenderCreateQuestionComponent,
} from "../../../reducers/componentStates";
import { addEmptyQuestionAtIndex } from '../../../reducers/assessment';
import { initialQuestionState } from '../../../data/constants/initialQuestionState';
import QuestionBank from "../questionBank/questionBank";

const InsertQuestionComponent = ({ index }) => {
  const dispatch = useDispatch();
  const inReview = useSelector((state) => state.componentStates.inReview);
  const assessmentState = useSelector((state) => state.assessment);
  const instructions = assessmentState.instructions;
  const assessmentPaper = assessmentState.assessmentPaper;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const getDrawerWidth = () => {
    if (isSmallScreen) {
      return '100%';
    } else {
      return '55%';
    }
  };

  const handleCreateQuestionClick = () => {
    dispatch(setCurrentQuestionIndex(index));
    dispatch(addEmptyQuestionAtIndex({ index, initialQuestionState }));
    dispatch(isCreateQuestion(true));
    dispatch(setRenderCreateQuestionComponent(false));
  };

  const handleDragQuestionClick = () => {
    dispatch(setCurrentQuestionIndex(index));
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const showOptions = (instructions !== "" && assessmentPaper && assessmentPaper.length > 0) ||
                      (instructions !== "") ||
                      (instructions === "" && assessmentPaper && assessmentPaper.length > 0);

  return (
    <Box mt={2} mb={2}
      sx={{
        '&:hover .hoverVisible': { visibility: 'visible' },
      }}
    >
      {!inReview && showOptions && (
        <Grid container justifyContent="center" alignItems="center" spacing={4}>
          <Grid item>
            <Box
              onClick={handleDragQuestionClick}
              className="hoverVisible"
              sx={{
                cursor: 'pointer',
                color: 'grey',
              }}
            >
              <IconButton color="inherit" size="small">
                <LibraryBooksIcon />
              </IconButton>
              <Typography variant="caption" color="inherit">
                Open Question Bank
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box
              onClick={handleCreateQuestionClick}
              className="hoverVisible"
              sx={{
                cursor: 'pointer',
                color: 'grey',
              }}
            >
              <IconButton color="inherit" size="small">
                <AddIcon />
              </IconButton>
              <Typography variant="caption" color="inherit">
                Create Question
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}

      {/* Drawer Component */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
        PaperProps={{
          sx: {
            width: getDrawerWidth()
          }
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between" p={2} borderBottom="1px solid #ddd">
          <Typography variant="h6">Question Bank</Typography>
          <IconButton onClick={handleCloseDrawer}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box p={2}>
          <QuestionBank />
        </Box>
      </Drawer>
    </Box>
  );
};

export default InsertQuestionComponent;
