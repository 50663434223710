import React, { useEffect, useState } from "react";
import "./home.css";
import { Box, Button, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TestWidget from "./testWidget";
import CircularProgress from "@mui/material/CircularProgress";
import AssessmentAPIService from "../../services/api/assessmentAPIService";
import FeatureAnnouncementModal from "../FeatureAnnouncement/FeatureAnnouncement";
import { populateState } from "../../reducers/assessment";
import { resetState } from "../../reducers/componentStates";
import { addSelectedTopic } from "../../reducers/selectedTopics";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useRollbar } from "@rollbar/react";

// Mock data for marked tests
const mockMarkedTests = [
  {
    id: 1,
    name: "Maths Test 1",
    average_score: 75,
    assessment_date: "2024-08-20"
  },
  {
    id: 2,
    name: "Science Test 1",
    average_score: 82,
    assessment_date: "2024-08-18"
  },
  {
    id: 3,
    name: "History Test 1",
    average_score: 68,
    assessment_date: "2024-08-15"
  },
  {
    id: 4,
    name: "Geography Test 1",
    average_score: 90,
    assessment_date: "2024-08-12"
  }
];

const Home = () => {
  const [recentlyWorkedOn, setRecentlyWorkedOn] = useState([]);
  const [baselineTests, setBaselineTests] = useState([]);
  const [upcomingTests, setUpcomingTests] = useState([]);
  const [markedTests, setMarkedTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);
  const [visibleWidgets, setVisibleWidgets] = useState(5);
  const [showAllMarkedTests, setShowAllMarkedTests] = useState(false); // State for showing all marked tests
  const dispatch = useDispatch();
  const rollbar = useRollbar();
  const navigate = useNavigate();

  useEffect(() => {
    const updateVisibleWidgets = () => {
      if (window.innerWidth <= 2000) {
        setVisibleWidgets(3);
      } else {
        setVisibleWidgets(4);
      }
    };

    // Initial check
    updateVisibleWidgets();

    // Add event listener for window resizing
    window.addEventListener('resize', updateVisibleWidgets);

    return () => window.removeEventListener('resize', updateVisibleWidgets);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;

      const assessments = await AssessmentAPIService.shallowFetchAssessments(token);

      if (assessments) {
        const sortedByDate = assessments.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));

        setRecentlyWorkedOn(sortedByDate.slice(0, visibleWidgets));
        setBaselineTests(assessments.filter(test => test.type === "baseline_test").slice(0, visibleWidgets));
        setUpcomingTests(assessments.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date)));
        setMarkedTests(mockMarkedTests);
      }

      const announcementData = await AssessmentAPIService.fetchFeatureAnnouncements(token);
      if (announcementData && announcementData.length > 0) {
        setAnnouncements(announcementData);
        setModalOpen(true);
      }

      setLoading(false);
    };

    fetchData();
  }, [visibleWidgets]); // Re-fetch when the number of visible widgets changes

  const handleSeeAll = () => {
    navigate('/exams-tests');
  };

  const fetchFullExamData = async (assessmentId) => {
    try {
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;

      const response = await AssessmentAPIService.fetchAssessment(assessmentId, token);
      if (response) {
        return await response;
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch assessment data. Please try again later.'
        });
        return null;
      }
    } catch (error) {
      rollbar.error("Error fetching full exam data:", error);
      console.error("Error fetching full exam data:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.'
      });
      return null;
    }
  };

  const handleViewResults = async (testId) => {
    try {
      const assessment = await fetchFullExamData(testId);

      if (assessment) {
        dispatch(populateState(assessment));
        dispatch(resetState());

        await dispatch(addSelectedTopic(assessment.topics || []));

        navigate(`/review-tests/${testId}`);
      } else {
        rollbar.warning("Failed to fetch full assessment data.");
        console.error("Failed to fetch full assessment data.");
      }
    } catch (error) {
      rollbar.error("Error during handleViewResults:", error);
      console.error("Error during handleViewResults:", error);
    }
  };

  const handleEdit = async (paperId) => {
    try {
      const assessment = await fetchFullExamData(paperId);

      if (assessment) {
        dispatch(populateState(assessment));
        dispatch(resetState());

        await dispatch(addSelectedTopic(assessment.topics || []));

        navigate("/assessment-details");
      } else {
        rollbar.warning("Failed to fetch full assessment data.");
        console.error("Failed to fetch full assessment data.");
      }
    } catch (error) {
      rollbar.error("Error during handleEdit:", error);
      console.error("Error during handleEdit:", error);
    }
  };

  const handleModalClose = () => {
    if (currentAnnouncementIndex < announcements.length - 1) {
      setCurrentAnnouncementIndex(currentAnnouncementIndex + 1);
    } else {
      setModalOpen(false);
    }
  };

  if (loading) {
    return (
      <div className="loading-overlay">
        <CircularProgress />
      </div>
    );
  }

  const handleDelete = (testId) => {
    setRecentlyWorkedOn(recentlyWorkedOn.filter(test => test.id !== testId));
    setBaselineTests(baselineTests.filter(test => test.id !== testId));
    setUpcomingTests(upcomingTests.filter(test => test.id !== testId));
  };

  const noTestsAvailable = recentlyWorkedOn.length === 0 && baselineTests.length === 0 && upcomingTests.length === 0 && markedTests.length === 0;

  return (
    <div className="dashboard">
      <FeatureAnnouncementModal
        open={modalOpen}
        onClose={handleModalClose}
        announcement={announcements[currentAnnouncementIndex]}
      />

      <div className="main-content">
        {!noTestsAvailable && (
          <Box className="overview-screen-header">
            <div className="overview-screen-header-content">
              <div className="overview-screen-right-section">
              </div>
            </div>
          </Box>
        )}

        {noTestsAvailable ? (
          <div className="no-tests-container" style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h5" gutterBottom>
              Thank you for using Educase
            </Typography>
            <Typography variant="h6" gutterBottom>
              Click the button below to create your first paper
            </Typography>
            <Link to="/assessment-details">
              <Button
                className="create-paper-button"
                variant="contained"
                style={{ backgroundColor: 'primary', color: 'white', marginTop: '20px' }}
              >
                Create Paper
              </Button>
            </Link>
          </div>
        ) : (
          <>
            {recentlyWorkedOn.length > 0 && (
              <div className="scroll-section">
                <h3 className="section-heading">Here's what you've recently worked on</h3>
                <div className="horizontal-grid">
                  {recentlyWorkedOn.slice(0, visibleWidgets).map((paper, index) => (
                    <TestWidget
                      key={index}
                      testId={paper.id}
                      testName={paper.name}
                      grade={paper.grade}
                      subject={paper.subject.name}
                      date={paper.assessment_date}
                      totalMarks={paper.total_marks}
                      lastUpdated={paper.last_updated}
                      onDelete={handleDelete}
                      handleEdit={handleEdit}
                      handleViewResults={handleViewResults}
                    />
                  ))}
                </div>
                <Button
                  className="see-all-button"
                  onClick={handleSeeAll}
                >
                  See All
                </Button>
              </div>
            )}
{baselineTests.length > 0 && (
  <div className="scroll-section">
    <h3 className="section-heading">Baseline Tests</h3>
    <div className="horizontal-grid">
      {baselineTests.slice(0, 3).map((test, index) => (
        <TestWidget
          key={index}
          testId={test.id}
          testName={test.name}
          grade={test.grade}
          subject={test.subject.name}
          date={test.assessment_date}
          totalMarks={test.total_marks}
          lastUpdated={test.last_updated}
        />
      ))}
    </div>
    <Button
      className="see-all-button"
      onClick={() => navigate('/exams-tests?sortBy=Baseline Test')}
    >
      See All
    </Button>
  </div>
)}



            {markedTests.length > 0 && (
              <div className="scroll-section">
                <h3 className="marked-tests-heading">Here are recently marked tests</h3>
                <div className="marked-tests-list">
                  {markedTests.slice(0, showAllMarkedTests ? markedTests.length : 4).map((test, index) => (
                    <div key={index} className="marked-test-item">
                      <div className="test-name">{test.name}</div>
                      <div className="test-score">Average Score: {Math.round(test.average_score)}%</div>
                      <div className="test-date">{new Date(test.assessment_date).toLocaleDateString()}</div>
                    </div>
                  ))}
                </div>
                  <Button
                    className="see-all-button"
                    onClick={() => setShowAllMarkedTests(!showAllMarkedTests)}
                  >
                    See All
                  </Button>
              </div>
            )}
          </>
        )}
      </div>

      {upcomingTests.length > 0 && (
        <div className="upcoming-tests-section">
          <h5 className="section-heading">Upcoming Tests</h5>
          <div className="upcoming-tests-list">
            {upcomingTests.slice(0, 6).map((test, index) => (
              <React.Fragment key={index}>
                <div
                  className="upcoming-test-item"
                  onClick={() => handleEdit(test.id)}
                  style={{ cursor: 'pointer' }}
                >
                  <div>
                    <div className="test-name">{test.name}</div>
                    <div className="test-date">{new Date(test.assessment_date).toLocaleDateString()}</div>
                  </div>
                </div>
                {index < upcomingTests.length - 1 && <div className="divider"></div>}
              </React.Fragment>
            ))}
            <Button onClick={() => navigate('/exams-tests')}>
              See All
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;