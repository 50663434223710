import React, { useState, useEffect } from 'react';
import './featureSlider.css';
import realTimeMonitoringImage from '../../../assets/analysis.png';
import aiPoweredAssessmentImage from '../../../assets/create.png';
import accessibleLearningImage from '../../../assets/upload_answers.png';
import personalFeedback from '../../../assets/personal_feedback.png';

const FeatureSlider = () => {
  const featureCards = [
    {
      title: 'Create tests and exams in less than 10 minutes.',
      description: (
        <div className="feature-slider-product-intro-text">
          <ul className="feature-slider-features-list">
            <li>Personalized Test/Exam Cover</li>
            <li>Access to past exam questions and papers (Grade 4-12)</li>
            <li>PDF download</li>
            <li>Tags with topic, cognitive level</li>
            <li>Automatic Total Counter</li>
          </ul>
        </div>
      ),
      imageUrl: aiPoweredAssessmentImage,
      color: '#fffbef'
    },
    {
      title: 'We take care of all the marking so you don’t have to.',
      description: (
        <div className="feature-slider-product-intro-text">
          <ul className="feature-slider-features-list">
            <li>Upload handwritten scripts using images or a scan</li>
            <li>Three Days to return the marks</li>
            <li>Record the marks on the system</li>
            <li>Generic or personalized corrections</li>
          </ul>
        </div>
      ),
      imageUrl: accessibleLearningImage,
      color: '#ffd3b6'
    },
    {
      title: 'Experience Friendly Personalized Feedback',
      description: (
        <div className="feature-slider-product-intro-text">
          <ul className="feature-slider-features-list">
            <li>Friendly personalized feedback</li>
            <li>Tricks and examples on answers</li>
            <li>Text to audio for accessibility</li>
          </ul>
        </div>
      ),
      imageUrl: personalFeedback,
      color: '#b8d9bb'
    },
    {
      title: 'Get Instant Insights with Real-time Monitoring',
      description: (
        <div className="feature-slider-product-intro-text">
          <ul className="feature-slider-features-list">
            <li>Get metrics per class, school, and up to district level</li>
            <li>Get number of learners that passed, failed, or skipped question</li>
            <li>Generate report cards</li>
          </ul>
        </div>
      ),
      imageUrl: realTimeMonitoringImage,
      color: '#ffaaa5'
    },
  ];

  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="feature-slider-container">
      <h3 className="feature-slider-header">
        Seamlessly manage your testing process
      </h3>
      {featureCards.map((card, index) => {
        const cardOffset = index * window.innerHeight;
        const isCardSticky = scrollY >= cardOffset - 90 && scrollY < cardOffset + window.innerHeight;

        return (
          <div
            key={index}
            className={`feature-slider-card ${isCardSticky ? 'sticky' : ''}`}
            style={{
              backgroundColor: card.color,
            }}
          >
            <div className="feature-slider-card-content">
              <h3 className="feature-slider-title">{card.title}</h3>
              <div>{card.description}</div> {/* Render description as JSX */}
            </div>
            <div className="feature-slider-card-image">
              <img src={card.imageUrl} alt={card.title} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FeatureSlider;
