import React, { useRef, useState, useEffect } from 'react';
import Header from '../../components/landingPage/header/header';
import './landingPage.css';
import HeroSection from '../../components/landingPage/heroSection/heroSection';
import Footer from '../../components/landingPage/footer/footer';
import Features from '../../components/landingPage/features/features';
import PricingCards from '../../components/landingPage/pricingCards/pricingCards';
import Scenarios from "../../components/landingPage/scenarios/scenarios";
import Testimonials from '../../components/landingPage/testimonials/testimonials';
import FeatureSlider from "../../components/landingPage/featureSlider/featureSlider";
import TestingProcessValue from "../../components/landingPage/testingProcessValue/testingProcessValue";
import UserRolesSlider from "../../components/landingPage/userRolesSlider/userRolesSlider";

const LandingPage = () => {
  const landingPageRef = useRef(null);
  const calendlyRef = useRef(null);

  // State to track the screen size
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 750);

  // Function to update screen size on resize
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 750);
  };

  useEffect(() => {
    // Add event listener to handle window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const scrollToCalendly = () => {
    calendlyRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="landing-page-container" ref={landingPageRef}>
      <Header />
      <HeroSection scrollToCalendly={scrollToCalendly} />
      <Scenarios />
      {/* Conditional rendering based on screen size */}
      {isSmallScreen ? <Features /> : <FeatureSlider />}

      {/* Testing Process Value Section shown only on small screens */}
      <TestingProcessValue />

      <UserRolesSlider />
      {/* Testimonials Section */}
      <Testimonials />
      {/* Pricing Plans Section */}
      <PricingCards />
      <Footer />
    </div>
  );
};

export default LandingPage;
