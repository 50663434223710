import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, IconButton, TextField } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import CourseApiService from "../../../services/api/courseAPIService";

const UploadLearnerScripts = () => {
  const { courseId } = useParams();
  const [learners, setLearners] = useState([]);
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [filteredLearners, setFilteredLearners] = useState([]); // State for filtered learners
  const [fileInputRefs, setFileInputRefs] = useState({}); // To manage file inputs for each learner

  useEffect(() => {
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    const fetchLearners = async () => {
      try {
        const response = await CourseApiService.getLearnersForCourse(courseId, token);
        if (response && response.status === 200) {
          const learnersData = await response.json();
          setLearners(learnersData);
          setFilteredLearners(learnersData); // Initialize filtered learners
          // Initialize refs for file inputs
          setFileInputRefs(learnersData.reduce((acc, learner) => {
            acc[learner.id] = React.createRef();
            return acc;
          }, {}));
        } else {
          console.error("Failed to fetch learners.");
        }
      } catch (error) {
        console.error("Error fetching learners:", error);
      }
    };

    fetchLearners(); // Fetch learners when the component mounts
  }, [courseId]);

  // Function to create initials from names
  const createInitials = (firstName, lastName) => {
    return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0).toUpperCase()}`;
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    // Filter learners based on the search term
    const filtered = learners.filter((learner) =>
      `${learner.first_name} ${learner.last_name}`.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredLearners(filtered);
  };

  // Handle file input change
  const handleFileChange = (learnerId, event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      console.log(`Uploading script for learner ID ${learnerId}:`, file);
      // You can add logic here to handle the file upload to your backend
    }
  };

  return (
    <Box sx={{ overflowY: 'auto', height: 'auto', padding: 2 }}>
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ backgroundColor: 'white', borderRadius: '16px', border: '2px solid #cfd8dc', boxShadow: 'none', width: '95%', minHeight: '80vh' }}>
        <Typography variant="h6" gutterBottom>
          Learners
        </Typography>
        {/* Search Field */}
        <TextField
          variant="outlined"
          placeholder="Search Learners..."
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ marginBottom: 2, width: '90%' }} // Style the search input
        />
        <Box display="flex" flexWrap="wrap" justifyContent="space-between">
          {filteredLearners.map((learner) => (
            <Box
              key={learner.id}
              display="flex"
              flexDirection="column"
              alignItems="center"
              mx={1}
              mb={2}
              flexBasis="20%"
              maxWidth="20%"
              sx={{
                '@media (max-width: 600px)': {
                  flexBasis: '50%',
                  maxWidth: '50%',
                },
                '@media (max-width: 400px)': {
                  flexBasis: '100%',
                  maxWidth: '100%',
                },
              }}
            >
              <div
                style={{
                  borderRadius: '50%',
                  width: 60,
                  height: 60,
                  backgroundColor: '#5F9EA0',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'white',
                  fontSize: '1.5rem',
                  marginBottom: '4px', // Add space below the avatar
                }}
              >
                {createInitials(learner.first_name, learner.last_name)}
              </div>
              <Box display="flex" flexDirection="column" alignItems="center" mb={1}>
                <Typography variant="body2" textAlign="center" mb={0.5}>
                  {`${learner.first_name} ${learner.last_name}`}
                </Typography>
                <input
                  accept="image/*,.pdf" // Accept images and PDF files
                  style={{ display: 'none' }}
                  type="file"
                  ref={fileInputRefs[learner.id]}
                  capture="environment" // Open the camera for taking pictures
                  onChange={(event) => handleFileChange(learner.id, event)}
                />
                <IconButton
                  onClick={() => fileInputRefs[learner.id].current.click()} // Trigger the file input click
                  color="primary"
                  sx={{ fontSize: '1.5rem' }}
                >
                  <UploadIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default UploadLearnerScripts;
