import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import { TextField, Typography, Button, Box } from "@mui/material";
import Swal from "sweetalert2";
import CryptoJS from "crypto-js";
import getConfig from "../../config/index";

const CopyTestLinkDialog = ({ open, onClose, selectedAssessmentId, parsedUserInfo }) => {
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryTime, setExpiryTime] = useState("");
  const [editableEmail, setEditableEmail] = useState(parsedUserInfo.email);
  const [passPercentage, setPassPercentage] = useState(40);
  const SECRET_KEY = "your-secret-key"; // Replace with your actual secret key

  const encryptData = (data) => {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  };

  const handleCopyLink = () => {
    const config = getConfig();
    const selectedAssessment = selectedAssessmentId;

    if (selectedAssessment) {
      const encryptedEmail = encryptData(editableEmail);
      const encryptedExpiryDateTime = encryptData(`${expiryDate}T${expiryTime}`);
      const encryptedPassPercentage = encryptData(passPercentage);

      const linkWithExpiry = `${config.api.fe_base_url}/test-welcome-page?assessment_id=${selectedAssessment}&expiry=${encodeURIComponent(
        encryptedExpiryDateTime
      )}&teacher=${encodeURIComponent(encryptedEmail)}&passPercentage=${encodeURIComponent(encryptedPassPercentage)}`;

      navigator.clipboard
        .writeText(linkWithExpiry)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Test link copied to clipboard",
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
          Swal.fire({
            icon: "error",
            title: "Failed to copy test link",
          });
        });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" PaperProps={{
      style: {
        border: "1px solid #5f9ea0",
        borderRadius: "8px",
        boxShadow: "none"
      }
    }}>
      <div style={{ backgroundColor: '#f1f3f5', padding: '15px 20px' }}> {/* Grey background */}
        <Typography variant="h6" style={{ textAlign: "center", color: '#5f9ea0', fontWeight: 'bold' }}>
          Copy Test Link
        </Typography>
      </div>
      <Divider /> {/* Divider where the background ends */}

      <DialogContent>
        <Typography variant="body1" style={{ marginBottom: '15px', color: '#6995a7' }}>
          Share this link with your learners. They will upload their answers, and Educase will notify you when the marks are ready.
        </Typography>

        {/* Input fields */}
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Expiry Date<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="date"
              fullWidth
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Expiry Time<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="time"
              fullWidth
              value={expiryTime}
              onChange={(e) => setExpiryTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Teacher's Email<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="email"
              fullWidth
              value={editableEmail}
              onChange={(e) => setEditableEmail(e.target.value)}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>

          <Box>
            <Typography variant="body2" style={{ marginBottom: '5px', fontWeight: 'bold', color: '#777f86' }}>
              Pass Percentage<span style={{ color: 'red' }}>*</span>
            </Typography>
            <TextField
              type="text"
              fullWidth
              value={`${passPercentage}%`}
              onChange={(e) => setPassPercentage(e.target.value.replace('%', ''))}
              InputProps={{
                style: {
                  height: '45px', // Uniform height
                  fontSize: '16px',
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions style={{ justifyContent: 'center', padding: '15px', gap: '10px' }}>
        <Button onClick={onClose} style={{ backgroundColor: '#f5f5f5', color: '#000', borderRadius: '5px', padding: '8px 20px' }}>
          Cancel
        </Button>
        <Button onClick={handleCopyLink} style={{ backgroundColor: '#5f9ea0', color: '#fff', borderRadius: '5px', padding: '8px 20px' }}>
          Copy Link
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CopyTestLinkDialog;
