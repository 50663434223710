import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import AssessmentDetailsStep from "./assessmentDetailStep";
import SelectTopicsStep from "./selectTopicsStep";
import CreateAssessmentPaperStep from "./createAssessmentPaperStep";
import { useSelector, useDispatch } from "react-redux";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { populateExamDetails } from "../../reducers/assessment";
import { setIsCreateQuestionVisible } from "../../reducers/componentStates";

export default function CreateAssessmentWizard() {
    const [activeStep, setActiveStep] = useState(0);
    const [stepOneErrors] = useState({});
    const assessmentState = useSelector((state) => state.assessment);
    const dispatch = useDispatch();
    const selectedTopicsState = useSelector((state) => state.selectedTopics);

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handlePreviousStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

        useEffect(() => {
        // Hide the "Create Assessment" button when this component is mounted
        dispatch(setIsCreateQuestionVisible(false));

        // Show the "Create Assessment" button again when this component is unmounted
        return () => {
            dispatch(setIsCreateQuestionVisible(true));
        };
    }, [dispatch]);

    return (
        <Box sx={{ width: "100%" }}>
            {activeStep === 0 && (
                    <AssessmentDetailsStep
                    handleStepOneChange={(event) => dispatch(populateExamDetails([event.target.name, event.target.value]))}
                    errors={stepOneErrors}
                    handleNextStep={handleNextStep}
                />
            )}

            {activeStep === 1 && (
                <SelectTopicsStep
                    grade={assessmentState.grade}
                    subject={assessmentState.subject}
                    subject_name={assessmentState.subject_name}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
               />
            )}

            {activeStep === 2 && (
                <DndProvider backend={HTML5Backend}>
                    <CreateAssessmentPaperStep
                        selectedTopics={selectedTopicsState}
                        handlePreviousStep={handlePreviousStep}
                    />
                </DndProvider>
            )}
        </Box>
    );
}
