import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Avatar, IconButton, Box } from "@mui/material";
import TaskIcon from '@mui/icons-material/Task';

const ClassList = ({ studentsData, onSelectStudent }) => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        // Assuming you get student data via `studentsData` prop or API call
        if (studentsData) {
            setRows(
                studentsData.map((student, index) => ({
                    id: index,
                    avatar: student.avatar, // Image URL or initials
                    name: student.name,
                    lastname: student.lastname,
                    testDate: student.testDate, // Date they took the test
                    progress: student.progress, // Marking progress percentage
                    finalMark: student.finalMark // Final mark
                }))
            );
        }
    }, [studentsData]);

    const columns = [
        {
            field: "avatar",
            headerName: "Avatar",
            width: 100,
            renderCell: (params) => (
                <Avatar alt={params.row.name} src={params.value}>
                    {params.row.name[0]}{params.row.lastname[0]} {/* Initials if no avatar */}
                </Avatar>
            )
        },
        { field: "name", headerName: "First Name", width: 150 },
        { field: "lastname", headerName: "Last Name", width: 150 },
        {
            field: "testDate",
            headerName: "Test Date",
            width: 150,
            renderCell: (params) => new Date(params.value).toLocaleDateString() // Formatting date
        },
        {
            field: "progress",
            headerName: "Marking Progress",
            width: 200,
            renderCell: (params) => (
                <Box>
                    {params.value}%
                </Box>
            )
        },
        {
            field: "finalMark",
            headerName: "Final Mark",
            width: 150,
            renderCell: (params) => (
                params.value !== null ? (
                    <Box>{params.value}</Box>
                ) : (
                    <IconButton
                        onClick={() => onSelectStudent(params.row)} // Trigger mark selection
                    >
                        <TaskIcon />
                    </IconButton>
                )
            )
        }
    ];

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection={false}
                disableSelectionOnClick
            />
        </div>
    );
};

export default ClassList;