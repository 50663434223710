import getConfig from "../../config/index"; // Adjust the path as necessary

const config = getConfig();

export const CourseApiService = {
    // Fetch all courses for the authenticated teacher
    async getCoursesForTeacher(token) {
        try {
            const response = await fetch(`${config.api.base_url}/courses/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch courses');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error fetching courses:', error);
            throw error;
        }
    },

    // Create a new course
    async createCourse(courseData, token) {
        try {
            const response = await fetch(`${config.api.base_url}/courses/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(courseData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to create course');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error creating course:', error);
            throw error;
        }
    },

    // Update an existing course
    async updateCourse(courseId, updatedData, token) {
        try {
            const response = await fetch(`${config.api.base_url}/courses/${courseId}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(updatedData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update course');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error updating course:', error);
            throw error;
        }
    },

    // Delete a course
    async deleteCourse(courseId, token) {
        try {
            const response = await fetch(`${config.api.base_url}/courses/${courseId}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to delete course');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error deleting course:', error);
            throw error;
        }
    },

    // Enroll a learner into a course
    async enrollLearner(learnerData, token) {
        try {
            const response = await fetch(`${config.api.base_url}/enrollments/enroll-learner/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
                body: JSON.stringify(learnerData)
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to enroll learner');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error enrolling learner:', error);
            throw error;
        }
    },


async removeLearnerFromCourse(learnerId, token) {
    try {
        const response = await fetch(`${config.api.base_url}/enrollments/${learnerId}/remove-from-course/`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            }
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to remove learner');
        }

        return response;  // Return the raw response object to handle in the frontend
    } catch (error) {
        console.error('Error removing learner:', error);
        throw error;
    }
},


    // Fetch learners for a specific course
    async getLearnersForCourse(courseId, token) {
        try {
            const response = await fetch(`${config.api.base_url}/enrollments/${courseId}/learners/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch learners');
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error('Error fetching learners:', error);
            throw error;
        }
    },

    // Update a learner
async updateLearner(learnerId, updatedData, token) {
    try {
        const response = await fetch(`${config.api.base_url}/learners/${learnerId}/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify(updatedData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Failed to update learner');
        }

        return response;  // Return the raw response object to handle in the frontend
    } catch (error) {
        console.error('Error updating learner:', error);
        throw error;
    }
},

};

export default CourseApiService;
