import React, { useState } from "react";
import GeneralAITools from "./GeneralTools/index";
import EnglishAITools from "./EnglishAITools/EnglishAITools";
import "./index.css";

const AssessmentTools = () => {
  const [activeTab, setActiveTab] = useState("general");

  return (
    <div>
      {/* Tab Navigation */}
      <div className="tab-navigation">
        <button
          className={activeTab === "general" ? "active-tab" : "tab"}
          onClick={() => setActiveTab("general")}
        >
          General
        </button>
        <button
          className={activeTab === "english" ? "active-tab" : "tab"}
          onClick={() => setActiveTab("english")}
        >
          English
        </button>
      </div>

      {/* Conditionally render General or English content based on activeTab */}
      <div className="tab-content">
        {activeTab === "general" && <GeneralAITools />}
        {activeTab === "english" && <EnglishAITools />}
      </div>
    </div>
  );
};

export default AssessmentTools;
