import React, { useEffect } from "react";
import AssessmentPaper from "../../components/CreateAssessment/assessmentPaper/assessmentPaper";
import { useSelector, useDispatch } from "react-redux";
import { Toolbar } from "@mui/material";
import { updateMarks } from "../../reducers/assessment";

const CreateAssessmentPaperStep = () => {
    const dispatch = useDispatch();
    const assessmentState = useSelector((state) => state.assessment);
    const assessmentPaper = assessmentState.assessmentPaper

    // Use the useEffect hook to watch for changes in the assessmentPaper state
    useEffect(() => {
            const calculateMarks = () => {
        let total_marks = 0;

        if (assessmentPaper.length === 0) {
            dispatch(updateMarks(0));
            return;
        }

        for (const question of assessmentPaper) {
            if (question.sub_questions && question.sub_questions.length > 0) {
                for (const subQuestion of question.sub_questions) {
                    total_marks += parseInt(subQuestion.marks) || 0;
                    // Check for nested sub-questions and add their marks
                    if (subQuestion.nested_sub_questions && subQuestion.nested_sub_questions.length > 0) {
                        for (const nestedSubQuestion of subQuestion.nested_sub_questions) {
                            total_marks += parseInt(nestedSubQuestion.marks) || 0;
                        }
                    }
                }
            } else {
                total_marks += parseInt(question.marks) || 0;
            }
        }
        dispatch(updateMarks(total_marks));
    };
            calculateMarks()
    }, [assessmentPaper, dispatch]);

    const handleMouseEnterAssessmentPaper = () => {
        document.body.style.overflow = "hidden";
    };

    const handleMouseLeaveAssessmentPaper = () => {
        document.body.style.overflow = "auto";
    };

    const containerStyle = {
        backgroundColor: "whitesmoke",
        width: "100%",
        marginInline: "auto",
    };

    return (
        <div style={{ position: "sticky", top: 0, zIndex: 1000, backgroundColor: "#fff" }}>
            <div style={{ display: "none" }}>
                <Toolbar
                    style={{
                        justifyContent: "flex-end",
                        display: "none"
                    }}
                >
                </Toolbar>
            </div>

            {/* Approve Paper Confirmation Modal */}
            <div className="dragndrop">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        overflowY: "hidden",
                    }}
                >
                    <div style={containerStyle}>
                        <AssessmentPaper
                            onEnter={handleMouseEnterAssessmentPaper}
                            onLeave={handleMouseLeaveAssessmentPaper}
                            total_marks={assessmentState.total_marks}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default CreateAssessmentPaperStep;
