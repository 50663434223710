import React, { useEffect, useState } from "react";
import './ExamCover.css';

const logoUrl = "https://assets.educasetools.com/Velle.png";

const ExamCover = ({ testName, grade, subject, date, totalMarks }) => {
  const [tutor, setTutor] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.name) {
      setTutor(user.name);
    }
  }, []);

  return (
    <div className="exam-cover">
      <div className="exam-content">
        <div className="exam-details">
          <div className="detail-item"><strong>{testName}</strong></div>
          <div className="detail-item"><strong>Tutor: </strong> {tutor}</div>
          <div className="detail-item"><strong>Grade: </strong> {grade}</div>
          <div className="detail-item"><strong>Subject: </strong> {subject}</div>
          <div className="detail-item"><strong>Total marks: </strong> {totalMarks} marks</div>
          <div className="detail-item"><strong>Date: </strong> {date}</div>
        </div>
        <div className="exam-logo-container">
          <img src={logoUrl} alt="Tutoring Company Logo" className="exam-logo" />
        </div>
      </div>
    </div>
  );
};

export default ExamCover;